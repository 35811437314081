import React from 'react';
import Header from "../../components/Layout/Header";
import AdminStats from "./stats";
import AdminTable from "./table";

interface IProps {
}

function AdminPage(props: IProps) {
  return (
    <div>
      <Header title="Administrators"/>

      <div className="px-4 grid gap-4">
        <AdminStats/>

        <AdminTable/>
      </div>
    </div>
  );
}

export default AdminPage;
