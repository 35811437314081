import React, {useCallback, useState} from 'react';
import {
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from "@chakra-ui/react";
import {IconDotsVertical} from "@tabler/icons-react";
import {useQuery, useQueryClient} from "react-query";
import adminService from "../../services/admin.service";
import Pagination from "../../components/Pagination";
import utils from "../../utils/Utils";

interface IProps {
}

function AdminTable(props: IProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const {data} = useQuery(["admins", page], () => adminService.getAdmins(null, page), {
    refetchOnWindowFocus: false,
    retry: 1,
    keepPreviousData: true,
  });

  const enableAdmin = useCallback((id: number) => {
    adminService.enableAdmin(id)
      .then(res => {
        toast({status: "success", title: "Admin enabled successfully"});
        queryClient.invalidateQueries(["admins", page]);
      })
      .catch(err => {
        utils.handleRequestError(err)
      });
  }, [page, queryClient, toast]);

  const disableAdmin = useCallback((id: number) => {
    adminService.disableAdmin(id)
      .then(res => {
        toast({status: "success", title: "Admin disabled successfully"});
        queryClient.invalidateQueries(["admins", page]);
      })
      .catch(err => {
        utils.handleRequestError(err)
      });
  }, [page, queryClient, toast]);

  const removeAdmin = useCallback((id: number) => {
    adminService.removeAdmin(id)
      .then(res => {
        toast({status: "success", title: "Admin removed successfully"});
        queryClient.invalidateQueries(["admins", page]);
      })
      .catch(err => {
        utils.handleRequestError(err)
      });
  }, [page, queryClient, toast]);

  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold">All Admins</h6>

        <TableContainer>
          <Table>
            <Thead>
              <Th>First Name</Th>
              <Th>Middle Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Status</Th>
              <Th>Enabled</Th>
              <Th>Action</Th>
            </Thead>
            <Tbody>
              {data?.data.data?.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.first_name}</Td>
                  <Td>{user.middle_name}</Td>
                  <Td>{user.last_name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{adminService.getRole(user)}</Td>
                  <Td>
                    <Tag colorScheme={user.accepted ? "green" : "gray"}>
                      <TagLabel>{user.accepted ? "Accepted" : "Pending"}</TagLabel>
                    </Tag>
                  </Td>
                  <Td>
                    <Tag colorScheme={user.disabled ? "red" : "green"}>
                      <TagLabel>{user.disabled ? "Disabled" : "Active"}</TagLabel>
                    </Tag>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<IconDotsVertical size={20}/>}
                        size="sm"
                        aria-label="Actions"
                        isRound
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => user.disabled ? enableAdmin(user.id) : disableAdmin(user.id)}
                        >
                          {user.disabled ? "Enable" : "Disable"}
                        </MenuItem>
                        <MenuItem onClick={() => removeAdmin(user.id)}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {
          data?.pagination &&
            <Pagination
                pageCount={Math.ceil(data.pagination.total / data.pagination.per_page)}
                onPageChange={e => setPage(e.selected + 1)}
                forcePage={(data.pagination.current_page ?? 1) - 1}
                pagination={data.pagination}
            />
        }
      </CardBody>
    </Card>
  );
}

export default AdminTable;
