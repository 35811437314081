import { INavItem } from "../../types/common";
import {
  IconAdjustments,
  IconHeadset,
  IconLayoutDashboard,
  IconReceipt,
  IconScanEye,
  IconUsers,
} from "@tabler/icons-react";

export const navItems: INavItem[] = [
  {
    name: "Dashboard",
    path: "/",
    icon: <IconLayoutDashboard />,
    roles: ["admin", "support", "finance"],
  },
  {
    name: "Users",
    path: "/",
    icon: <IconUsers />,
    roles: ["admin", "support"],
    sub: [
      {
        name: "Personal",
        path: "/users/personal",
      },
      {
        name: "Business",
        path: "/users/business",
      },
    ],
  },
  {
    name: "Transactions",
    path: "/transactions",
    icon: <IconReceipt />,
    roles: ["admin", "finance"],
  },
  {
    name: "CRM",
    path: "/",
    icon: <IconHeadset />,
    roles: ["admin", "support"],
    sub: [
      {
        name: "Groups",
        path: "/crm",
      },
      {
        name: "Messages",
        path: "/crm/messages",
      },
      {
        name: "Templates",
        path: "/crm/templates",
      },
    ],
  },
  {
    name: "Control Center",
    path: "/",
    icon: <IconAdjustments />,
    roles: ["admin"],
    sub: [
      {
        name: "Personal",
        path: "/control/personal",
        sub: [
          {
            name: "Bank Transfer",
            path: "/control/personal/bank-transfer",
          },
          {
            name: "Fago 2 Fago",
            path: "/control/personal/fago-fago",
          },
          {
            name: "Airtime to Cash",
            path: "/control/personal/airtime-cash",
          },
        ],
      },
      {
        name: "Business",
        path: "/control/business",
        sub: [
          {
            name: "Bank Transfer",
            path: "/control/business/bank-transfer",
          },
          {
            name: "Fago 2 Fago",
            path: "/control/business/fago-fago",
          },
        ],
      },
      {
        name: "Referral",
        path: "/control/referral",
      },
    ],
  },
  {
    name: "Admin",
    path: "/admin",
    icon: <IconScanEye />,
    roles: ["admin"],
  },
];
