import React, { TextareaHTMLAttributes } from "react";
import { Textarea } from "@chakra-ui/react";

interface IProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "size"> {
  error?: string | false;
  label?: string;
}

function TextAreaInput({ label, error, ...props }: IProps) {
  return (
    <div>
      <label>
        {!!label && <p className="mb-1">{label}</p>}
        <Textarea isInvalid={!!error} size="lg" {...props} />
      </label>
      {!!error && <p className="text-red-500 small mt-0.5">{error}</p>}
    </div>
  );
}

export default TextAreaInput;
