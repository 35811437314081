import React from 'react';
import Header from "../../../components/Layout/Header";
import {IconArrowNarrowRight} from "@tabler/icons-react";
import ReferralStats from "./stats";
import ReferralCategories from "./categories";

interface IProps {
}

function ReferralPage(props: IProps) {
  return (
    <div>
      <Header>
        CC <IconArrowNarrowRight className="mx-2"/> Referral
      </Header>

      <div className="px-4 grid gap-4">
        <ReferralStats/>

        <ReferralCategories/>
      </div>

    </div>
  );
}

export default ReferralPage;
