import React from "react";
import { Card, CardBody, Spinner } from "@chakra-ui/react";
import BalanceInflow from "./inflow";
import BalanceOutflow from "./outflow";
import { useQuery } from "react-query";
import transactionService from "../../../services/transaction.service";
import utils from "../../../utils/Utils";

interface IProps {}

function TotalBalance(props: IProps) {
  const {
    data: totalBalance,
    isLoading: totalBalanceLoading,
    isLoadingError: totalBalanceLoadingError,
    error: totalBalanceError,
  } = useQuery(
    ["transactions", "totalBalance"],
    transactionService.getTotalBalance,
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="bg-brand-grad p-5 flex items-center text-white gap-4">
          <p className="flex-1">Total Balance</p>
          <h6 className="font-semibold">
            {totalBalanceLoading ? (
              <Spinner size="sm" />
            ) : totalBalanceLoadingError && totalBalanceError ? (
              <span>Error occurred</span>
            ) : (
              `₦ ${utils.numberWithCommas(totalBalance?.balance)}`
            )}
          </h6>
        </div>
        <div className="p-6">
          <div className="grid gap-4">
            <BalanceInflow />
            <BalanceOutflow />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default TotalBalance;
