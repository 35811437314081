import React from 'react';
import Header from "../../../components/Layout/Header";
import {IconArrowNarrowRight} from "@tabler/icons-react";
import PersonalBalance from "./personalBalance";
import Tiers from "./tiers";
import UsersTransactionsChart from "./usersTransactionsChart";

interface IProps {
}

function CC_PersonalPage(props: IProps) {
  return (
    <div>
      <Header>
        CC <IconArrowNarrowRight className="mx-2"/> Personal
      </Header>

      <div className="grid gap-4 p-4">
        <div className="grid grid-cols-3 gap-4">
          <PersonalBalance/>
          <div className="grid gap-4">
            <Tiers/>
            <UsersTransactionsChart/>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CC_PersonalPage;
