import React from 'react';

interface IProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
}

function Header({title, children}: IProps) {
  return (
    <div className="w-full px-4 py-6 bg-white mb-4 border-b border-b-slate-200">
      <h4 className="font-bold flex items-center">{title ?? children}</h4>
    </div>
  );
}

export default Header;
