import React, { useCallback, useState } from "react";
import Header from "../../../components/Layout/Header";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ExportUsers from "../../Users/Personal/exportUsers";
import LoadingContainer from "../../../components/LoadingContainer";
import TdLink from "../../../components/TdLink";
import utils from "../../../utils/Utils";
import {
  IconArrowLeft,
  IconDotsVertical,
  IconInfoSquareRounded,
  IconUserCheck,
  IconUserOff,
} from "@tabler/icons-react";
import Pagination from "../../../components/Pagination";
import crmService from "../../../services/crm.service";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import userService from "../../../services/user.service";
import { Page } from "../../../types/common";
import { IUserDetails } from "../../../types/auth.types";
import SendMessage from "./sendMessage";

interface IProps {}

function CRMGroupPage(props: IProps) {
  const { groupId } = useParams<{ groupId: string }>();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const { data, isLoading, isLoadingError } = useQuery(
    ["crm", "groups", groupId, page, size],
    () => crmService.getGroupUsers(Number(groupId), page, size),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(userService.banUser, {
    onSuccess(newUserData) {
      if (data) {
        const oldData: Page<IUserDetails> = { ...data };
        const userIndex = oldData.data.findIndex(
          (u) => u.id === newUserData.id
        );
        if (userIndex >= 0) {
          oldData.data[userIndex] = newUserData;
          queryClient.setQueryData(
            ["crm", "groups", groupId, page, size],
            oldData
          );
        }
      }
    },
  });

  const fetchAllForExport = useCallback(async () => {
    const allUsers = await crmService.getGroupUsers(
      Number(groupId),
      1,
      data?.pagination?.total ?? 10
    );
    return allUsers.data;
  }, [data?.pagination?.total, groupId]);

  return (
    <div>
      <Header title={`CRM Group ${!!data?.group && `: ${data.group.name}`}`} />

      <div className="grid gap-4 px-4">
        <div>
          <Button variant="outline" colorScheme="brand" as={Link} to="/crm">
            <IconArrowLeft className="mr-2" /> Back
          </Button>
        </div>
        <Card>
          <CardBody>
            {isLoading ? (
              <LoadingContainer />
            ) : isLoadingError ? (
              <LoadingErrorContainer className="h-auto" />
            ) : (
              <div className="flex gap-6">
                <div className="flex-1">
                  <h6 className="font-semibold">{data?.group.name}</h6>
                  <p>{data?.group.description}</p>
                  <h1>{utils.numberWithCommas(data?.total_users)}</h1>
                </div>
                <div>
                  <SendMessage groupId={Number(groupId)} />
                </div>
              </div>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="flex items-center flex-wrap mb-4 gap-4">
              <h5 className="font-semibold">All Users</h5>
              <ExportUsers
                fetchAllForExport={fetchAllForExport}
                className="ml-auto"
              />
            </div>

            {isLoading ? (
              <LoadingContainer />
            ) : isLoadingError ? (
              <LoadingErrorContainer className="h-auto" />
            ) : (
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>S/N</Th>
                      <Th>First Name</Th>
                      <Th>Middle Name</Th>
                      <Th>Last Name</Th>
                      <Th>Email</Th>
                      <Th>Primary bank acc.</Th>
                      <Th>Status</Th>
                      <Th>BVN</Th>
                      <Th>KYC</Th>
                      <Th>Date joined</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data.map((user, index) => (
                      <Tr key={`tr-${user.id}`} className="with-hover">
                        <TdLink to={`/users/${user.id}`}>
                          {(page - 1) * size + (index + 1)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.first_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.middle_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.last_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.email)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.accountdetail?.bank_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag
                            colorScheme={user.is_restricted ? "red" : "green"}
                          >
                            <TagLabel>
                              {user.is_restricted ? "Restricted" : "Active"}
                            </TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag
                            colorScheme={!user.bvn_verified ? "red" : "green"}
                          >
                            <TagLabel>
                              {user.bvn_verified ? "Verified" : "Unverified"}
                            </TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag
                            colorScheme={!user.kyc_verified ? "red" : "green"}
                          >
                            <TagLabel>
                              {user.kyc_verified ? "Verified" : "Unverified"}
                            </TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.formatDate(new Date(user.created_at))}
                        </TdLink>
                        <Td>
                          <Menu isLazy>
                            <MenuButton
                              as={IconButton}
                              icon={<IconDotsVertical size={20} />}
                              size="sm"
                              aria-label="Actions"
                              isRound
                            />
                            <MenuList>
                              {user.is_restricted ? (
                                <MenuItem
                                  color="green.500"
                                  onClick={() =>
                                    mutate({ userId: user.id, ban: false })
                                  }
                                >
                                  <IconUserCheck className="mr-2" size={20} />
                                  Activate
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  color="red.500"
                                  onClick={() =>
                                    mutate({ userId: user.id, ban: true })
                                  }
                                >
                                  <IconUserOff className="mr-2" size={20} />
                                  Deactivate
                                </MenuItem>
                              )}
                              <MenuItem as={Link} to={`/users/${user.id}`}>
                                <IconInfoSquareRounded
                                  className="mr-2"
                                  size={20}
                                />
                                See more
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
            {data?.pagination && (
              <Pagination
                pageCount={Math.ceil(
                  data.pagination.total / data.pagination.per_page
                )}
                onPageChange={(e) => setPage(e.selected + 1)}
                forcePage={(data.pagination.current_page ?? 1) - 1}
                pagination={data.pagination}
                size={size}
                onSizeChange={setSize}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default CRMGroupPage;
