import {Page} from "../types/common";
import {IBusiness, IBusinessDetails} from "../types/business.types";
import apiInstance from "./api";
import {AxiosResponse} from "axios";
import {IUserDetails} from "../types/auth.types";

class BusinessService {
  getBusinesses(page: number = 1, size: number = 10, filter: object = {}): Promise<Page<IBusiness>> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/business/all", {params: {page, per_page: size, ...filter}})
        .then((res: AxiosResponse<Page<IBusiness>>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    });
  }

  getBusiness(id: string): Promise<IBusinessDetails> {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/business/${id}/details`)
        .then((res: AxiosResponse<{ data: IBusinessDetails }>) => {
          resolve(res.data.data);
        })
        .catch(err => reject(err));
    })
  }

  getBusinessManagers(id: string): Promise<IUserDetails[]> {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/business/${id}/users`)
        .then((res: AxiosResponse<{ users: { business_managers: IUserDetails[] } }>) => {
          resolve(res.data.users.business_managers);
        })
        .catch(err => reject(err));
    })
  }

  banBusiness({businessId, ban}: { businessId: string, ban: boolean }): Promise<string> {
    return new Promise((resolve, reject) => {
      apiInstance.post(ban ? "/admin/business/ban" : "/admin/business/unban", {business_id: businessId})
        .then((res: AxiosResponse<{ message: string }>) => {
          resolve(res.data.message)
        })
        .catch(err => reject(err));
    })
  }

  getCustomers(businessId: string, page: number = 1, size: number = 10) {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/business/${businessId}/customers`, {params: {page, per_page: size}})
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    });
  }
}

const businessService = new BusinessService();
export default businessService;
