import React from 'react';
import * as yup from "yup";
import {Field, FieldProps, Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import {Button, useToast} from "@chakra-ui/react";
import {Link, useNavigate} from "react-router-dom";

interface IProps {
}

function ResetPasswordPage(props: IProps) {
  const navigate = useNavigate();
  const toast = useToast();

  const validationSchema = yup.object().shape({
    password: yup.string()
      .matches(/^[\w\W]{6,30}$/, "Invalid pattern")
      .required("Required"),
    password2: yup.string()
      .test("confirm", "Passwords not matching",
        (value, context) => value === context.parent.password
      )
      .required("Required"),
  });

  const initialValues = {
    password: "",
    password2: "",
  }

  const onSubmit = () => {
    toast({
      title: "Password reset successful, please login",
      status: "success",
      isClosable: true
    })
    navigate("/auth", {replace: true});
  }

  return (
    <div>
      <h5 className="font-semibold">Reset Password</h5>
      <p className="text-slate-400 max-w-md">
        Set new password to secure your account
      </p>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({isSubmitting, isValid}) => (
          <Form className="grid grid-cols-1 gap-5 mt-6">
            <Field name="password">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="New Password"
                  placeholder="New Password"
                  error={meta.touched && meta.error}
                  type="password"
                  {...field}
                />
              )}
            </Field>

            <Field name="password2">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="New password again"
                  placeholder="New password again"
                  error={meta.touched && meta.error}
                  type="password"
                  {...field}
                />
              )}
            </Field>

            <div>
              <Button
                size="lg"
                colorScheme="brand"
                className="w-full"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Login
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <p className="mt-6">
        Remember password? <Link to="/auth" className="text-primary">Login</Link>
      </p>
    </div>
  );
}

export default ResetPasswordPage;
