import React from 'react';
import {
  Card,
  CardBody,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber
} from "@chakra-ui/react";

interface IProps {
}

function BusinessStats(props: IProps) {
  return (
    <div className="grid grid-cols-3 gap-4">
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>All Businesses</StatLabel>
            <StatNumber>345,670</StatNumber>
            <StatHelpText>
              <StatArrow type='increase'/>
              23.36%
            </StatHelpText>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>Active Businesses</StatLabel>
            <StatNumber>67,438</StatNumber>
            <StatHelpText>
              <StatArrow type='increase'/>
              23.36%
            </StatHelpText>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>Suspended Businesses</StatLabel>
            <StatNumber>163,384</StatNumber>
            <StatHelpText>
              <StatArrow type='decrease'/>
              23.36%
            </StatHelpText>
          </Stat>
        </CardBody>
      </Card>
    </div>
  );
}

export default BusinessStats;
