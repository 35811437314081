import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import React, { useMemo } from "react";
import { CRMSendMessageRequest } from "../../../types/crm.types";
import TextAreaInput from "../../../components/TextAreaInput";
import TextInput from "../../../components/TextInput";
import crmService from "../../../services/crm.service";
import { AxiosError } from "axios";
import isEmpty from "is-empty";
import { isValid } from "date-fns";

interface IProps {
  groupId: number;
}
function SendMessage({ groupId }: IProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const validationSchema = yup.object().shape({
    user_group_id: yup.number().required("User group is required"),
    message: yup.string().required("Message is required"),
    send_at: yup
      .date()
      .test("validDate", "Invalid date supplied", (value, context) => {
        if (context.parent.schedule && (isEmpty(value) || !isValid(value)))
          return false;
        return true;
      }),
  });

  const initialValues: CRMSendMessageRequest = useMemo(
    () => ({
      user_group_id: groupId,
      message: "",
      sms: false,
      in_app: false,
      email: false,
      push: false,
      schedule: false,
      send_at: undefined,
      save_template: false,
    }),
    [groupId]
  );

  const onSubmit = (
    values: CRMSendMessageRequest,
    helpers: FormikHelpers<CRMSendMessageRequest>
  ) => {
    crmService
      .sendMessage(values)
      .then((res) => {
        helpers.setSubmitting(false);
        toast({ status: "success", title: "Message sent successfully" });
        onClose();
      })
      .catch((err: AxiosError<any>) => {
        helpers.setSubmitting(false);
        toast({
          status: "error",
          title:
            err.response?.data?.message ?? "Unable to send message, try again!",
        });
      });
  };

  return (
    <>
      <Button size="lg" onClick={onOpen}>
        Send Message
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, isSubmitting, isValid }) => (
            <Form>
              <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Send Message</ModalHeader>
                <ModalBody pb={6}>
                  <div className="grid gap-6">
                    <Field name="message">
                      {({ field, meta }: FieldProps) => (
                        <TextAreaInput
                          label="Message"
                          placeholder="Message"
                          error={meta.touched && meta.error}
                          rows={10}
                          {...field}
                        />
                      )}
                    </Field>

                    <Field name="save_template">
                      {({ field, meta }: FieldProps) => (
                        <Checkbox {...field}>Save template</Checkbox>
                      )}
                    </Field>

                    <div>
                      <p className="small font-semibold">Delivery Channels</p>
                      <Stack direction="row" gap={6} mt={2}>
                        <Field name="sms">
                          {({ field, meta }: FieldProps) => (
                            <Checkbox {...field}>SMS</Checkbox>
                          )}
                        </Field>
                        <Field name="email">
                          {({ field, meta }: FieldProps) => (
                            <Checkbox {...field}>Email</Checkbox>
                          )}
                        </Field>
                        <Field name="in_app">
                          {({ field, meta }: FieldProps) => (
                            <Checkbox {...field}>In-App</Checkbox>
                          )}
                        </Field>
                        <Field name="push">
                          {({ field, meta }: FieldProps) => (
                            <Checkbox {...field}>Push Notification</Checkbox>
                          )}
                        </Field>
                      </Stack>
                    </div>

                    <div className="grid gap-2">
                      <p className="small font-semibold">Schedule</p>
                      <Field name="schedule">
                        {({ field, meta }: FieldProps) => (
                          <Checkbox {...field}>Send later</Checkbox>
                        )}
                      </Field>
                      {values.schedule && (
                        <Field name="send_at">
                          {({ field, meta }: FieldProps) => (
                            <TextInput
                              label="Send time"
                              placeholder="Send time"
                              type="datetime-local"
                              error={meta.touched && meta.error}
                              {...field}
                            />
                          )}
                        </Field>
                      )}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter gap={4}>
                  <Button size="lg" type="button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    size="lg"
                    type="submit"
                    colorScheme="brand"
                    isLoading={isSubmitting}
                    isDisabled={!isValid}
                  >
                    Send
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SendMessage;
