import React from 'react';
import {IconBell} from "@tabler/icons-react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure
} from "@chakra-ui/react";
import NotificationBody from "./body";

interface IProps {
}

function Notification(props: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <IconButton
        aria-label="Notification"
        icon={<IconBell/>}
        variant="outline"
        rounded="full"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="md">
        <DrawerOverlay/>
        <DrawerContent>
          <DrawerHeader>
            <DrawerCloseButton/>
            <h4>Notifications</h4>
          </DrawerHeader>
          <DrawerBody>
            <NotificationBody/>
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onClose}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Notification;
