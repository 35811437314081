import React from 'react';
import {IconLock} from "@tabler/icons-react";
import {
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import {IChangePassword} from "../../types/admin.types";
import TextInput from "../TextInput";
import authService from "../../services/auth.service";
import utils from "../../utils/Utils";
import toast from "react-hot-toast";

interface IProps {
}

function ChangePasswordModal(props: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  const validationSchema = yup.object().shape({
    oldPassword: yup.string().required("Reuired"),
    newPassword: yup.string().required("Reuired"),
    newPassword2: yup.string()
      .test("pwMatch", "Passwords not matching",
        (value, context) => value === context.parent.newPassword)
      .required("Reuired"),
  })

  const initialValues: IChangePassword = {
    oldPassword: "",
    newPassword: "",
    newPassword2: ""
  }

  const onSubmit = (values: IChangePassword, helpers: FormikHelpers<IChangePassword>) => {
    authService.updatePassword(values)
      .then(res => {
        toast.success("Password updated successfully");
        helpers.setSubmitting(false);
        onClose();
      })
      .catch(err => utils.handleRequestError(err, helpers));
  }

  return (
    <>
      <MenuItem onClick={onOpen}>
        <IconLock className="mr-2"/>
        Change password
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton/>
            Change Password
          </ModalHeader>

          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({isSubmitting, isValid}) => (
                <Form className="grid gap-4 pb-5">
                  <Field name="oldPassword">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Old password"
                        placeholder="Old password"
                        type="password"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="newPassword">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="New password"
                        placeholder="New password"
                        type="password"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="newPassword2">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="New password again"
                        placeholder="New password again"
                        type="password"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <div>
                    <Button
                      type="submit"
                      colorScheme="brand"
                      className="w-full"
                      size="lg"
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                    >
                      Change password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
