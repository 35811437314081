import React from 'react';
import Header from "../../../components/Layout/Header";
import BusinessStats from "./businessStats";
import BusinessTable from "./table";

interface IProps {
}

function UsersBusinessPage(props: IProps) {
  return (
    <div>
      <Header title="Businesses"/>

      <div className="grid grid-cols-1 gap-4 px-4">
        <BusinessStats/>
        <BusinessTable/>
      </div>
    </div>
  );
}

export default UsersBusinessPage;
