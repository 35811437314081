import React, {useCallback, useEffect, useState} from 'react';
import TransactionsTable from "../../../components/TransactionsTable";
import {useQuery} from "react-query";
import transactionService from "../../../services/transaction.service";
import LoadingContainer from "../../../components/LoadingContainer";
import {useParams} from "react-router-dom";

interface IProps {
}

function UserTransactions(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filterApplied, setFilterApplied] = useState({});

  useEffect(() => {
    setPage(1);
  }, [filterApplied]);

  const {data, isLoading} = useQuery(
    ["transactions", page, size, JSON.stringify({...filterApplied, user_id: id})],
    () => transactionService.getAllTransactions(page, size, {...filterApplied, user_id: id}),
    {keepPreviousData: true}
  );

  const fetchAllForExport = useCallback(async () => {
    const transactions = await transactionService.getAllTransactions(0, data?.pagination.total, {...filterApplied, user_id: id});
    return transactions.data;
  }, [data?.pagination.total, filterApplied, id]);

  return (
    isLoading ? (
      <LoadingContainer/>
    ) : !data ? (
      <div></div>
    ) : (
      <TransactionsTable
        data={data.data}
        pagination={data.pagination}
        setPage={setPage}
        applyFilter={setFilterApplied}
        appliedFilter={filterApplied}
        size={size}
        setSize={setSize}
        fetchAllForExport={fetchAllForExport}
      />
    )
  );
}

export default UserTransactions;
