import React from 'react';
import {
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stat,
  StatArrow,
  StatDownArrow,
  StatHelpText,
  StatNumber,
  useDisclosure
} from "@chakra-ui/react";
import {IconArrowUpRight} from "@tabler/icons-react";
import SelectInput from "../../../components/SelectInput";

interface IProps {
}

function BalanceOutflow(props: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <div className="grid grid-cols-3 items-center">
        <p>Outflow</p>
        <button
          onClick={onOpen}
          className="border border-slate-200 hover:border-primary apply-transition p-2 flex items-center gap-4 col-span-2">
          <StatDownArrow/>
          <p>₦300M</p>
          <IconArrowUpRight size={20} className="ml-auto text-slate-400"/>
        </button>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent className="overflow-hidden">
          <ModalBody className="p-0">
            <div className="px-6 py-4 flex items-center gap-4">
              <div className="flex-1">
                <h6 className="font-semibold">Outflow <StatDownArrow/></h6>
                <p className="small text-slate-400">
                  Total amount of money going out of FagoPay
                </p>
              </div>
              <div className="w-fit">
                <SelectInput options={[]} placeholder="12 March - 25 March 20224" size="sm"/>
              </div>
            </div>
            <div className="bg-brand-grad p-6 flex items-center text-white gap-4">
              <p className="flex-1">Total amount of money going out of FagoPay</p>
              <h6 className="font-semibold">₦100M</h6>
            </div>
            <div>
              <div className="px-6 py-4 flex items-center gap-4">
                <div className="flex-1">
                  <h6 className="font-semibold">VTPass</h6>
                  <p className="small text-slate-400">
                    Total amount of money coming into FagoPay through bank deposit and all
                  </p>
                </div>
                <div className="w-fit">
                  <Stat>
                    <StatNumber>345,670</StatNumber>
                    <StatHelpText>
                      <StatArrow type='increase'/>
                      23.36% from last week
                    </StatHelpText>
                  </Stat>
                </div>
              </div>

              <Divider/>

              <div className="px-6 py-4 flex items-center gap-4">
                <div className="flex-1">
                  <h6 className="font-semibold">Transfers out</h6>
                  <p className="small text-slate-400">
                    Total amount of money coming into FagoPay through bank deposit and all
                  </p>
                </div>
                <div className="w-fit">
                  <Stat>
                    <StatNumber>345,670</StatNumber>
                    <StatHelpText>
                      <StatArrow type='increase'/>
                      23.36% from last week
                    </StatHelpText>
                  </Stat>
                </div>
              </div>

              <Divider/>

              <div className="px-6 py-4 flex items-center gap-4">
                <div className="flex-1">
                  <h6 className="font-semibold">Refunds</h6>
                  <p className="small text-slate-400">
                    Total amount of money coming into FagoPay through bank deposit and all
                  </p>
                </div>
                <div className="w-fit">
                  <Stat>
                    <StatNumber>345,670</StatNumber>
                    <StatHelpText>
                      <StatArrow type='increase'/>
                      23.36% from last week
                    </StatHelpText>
                  </Stat>
                </div>
              </div>

              <Divider/>

              <div className="px-6 py-4 flex items-center gap-4">
                <div className="flex-1">
                  <h6 className="font-semibold">Operational expenses</h6>
                  <p className="small text-slate-400">
                    Total amount of money coming into FagoPay through bank deposit and all
                  </p>
                </div>
                <div className="w-fit">
                  <Stat>
                    <StatNumber>345,670</StatNumber>
                    <StatHelpText>
                      <StatArrow type='increase'/>
                      23.36% from last week
                    </StatHelpText>
                  </Stat>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BalanceOutflow;
