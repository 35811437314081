import apiInstance from "./api";
import {AxiosResponse} from "axios";
import {
  IReferralAdminStats,
  IReferralCategory,
  IReferralUserStats,
  IReferredUsersResponse
} from "../types/referral.types";

class ReferralService {
  getUserStats(id: string): Promise<IReferralUserStats> {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/referrals/stats/${id}`)
        .then((res: AxiosResponse<IReferralUserStats>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  }

  getOverview(): Promise<IReferralAdminStats> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/referrals/overview")
        .then((res: AxiosResponse<IReferralAdminStats>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  }

  getReferralCategories(): Promise<IReferralCategory[]> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/referrals/categories")
        .then((res: AxiosResponse<{ data: IReferralCategory[] }>) => {
          resolve(res.data.data);
        })
        .catch(err => reject(err));
    })
  }

  updateReferralCategory(data: IReferralCategory) {
    return new Promise((resolve, reject) => {
      apiInstance.post("/admin/referrals/categories", {
        ...data,
        "category_id": data.id
      })
        .then((res: AxiosResponse) => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }

  getReferredUsers(userId: string, page: number = 1, size: number = 10): Promise<IReferredUsersResponse> {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/referrals/user/${userId}`, {params: {per_page: size, page}})
        .then((res: AxiosResponse<IReferredUsersResponse>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  }
}

const referralService = new ReferralService();
export default referralService;
