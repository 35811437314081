import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import utils from "../../../utils/Utils";
import {IBusinessDetails} from "../../../types/business.types";

interface IProps {
}

function BusinessBankAccounts(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {data} = useQuery<IBusinessDetails>(["business", id]);
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Bank Accounts ({!!data?.account ? "1" : "0"})</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Bank Account</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              {
                !!data?.account ? (
                  <Box key={data?.account?.id} borderWidth="1px" borderRadius="lg" mt={2}>
                    <Table size="sm" variant="simple">
                      <Tbody>
                        <Tr>
                          <Td>Bank</Td>
                          <Td>{data?.account?.bank_name}</Td>
                        </Tr>
                        <Tr>
                          <Td>Account Name</Td>
                          <Td>{data?.account.account_name}</Td>
                        </Tr>
                        <Tr>
                          <Td>Account number</Td>
                          <Td>{data?.account.account_number}</Td>
                        </Tr>
                        <Tr>
                          <Td>Type</Td>
                          <Td>{data?.account.account_type}</Td>
                        </Tr>
                        <Tr>
                          <Td>Balance</Td>
                          <Td>
                            {data?.account.currency} {utils.numberWithCommas((data?.account.balance ?? 0).toFixed(2))}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Status</Td>
                          <Td>
                            <Tag
                              colorScheme={data?.account.status === "approved" ? "green" : "red"}>
                              <TagLabel>{data?.account.status?.toUpperCase() ?? "-"}</TagLabel>
                            </Tag>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                ) : (
                  <p>No bank account</p>
                )
              }
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessBankAccounts;
