import React, {useEffect, useState} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import referralService from "../../../services/referral.service";
import utils from "../../../utils/Utils";
import Pagination from "../../../components/Pagination";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import isEmpty from "is-empty";
import {useParams} from "react-router-dom";
import TdLink from "../../../components/TdLink";

interface IProps {
}

function ReferredUsers(props: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Referred Users</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Referred Users</ModalHeader>
          <ModalBody>
            <ReferredUsersContent/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function ReferredUsersContent() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const {id} = useParams<{ id: string }>();

  const {
    data,
    isLoading,
    isLoadingError,
    isRefetching,
    refetch
  } = useQuery(["referredUsers", id, page, size], () => !!id ? referralService.getReferredUsers(id, page, size) : undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setPage(1);
  }, [size]);

  if (!data || isLoading) {
    return (
      <div>
        <LoadingContainer/>
      </div>
    )
  }

  if (isLoadingError) {
    return (
      <div>
        <LoadingErrorContainer refetch={refetch} isRefetching={isRefetching}/>
      </div>
    )
  }

  return (
    <div className="pb-6">
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Th>S/N</Th>
            <Th>Name</Th>
            <Th>KYC</Th>
            <Th>Qualified Txns</Th>
            <Th>Date Joined</Th>
          </Thead>
          <Tbody>
            {
              isEmpty((data.data)) ? (
                  <Tr>
                    <Td colSpan={5} className="text-center py-10">No referred users</Td>
                  </Tr>
                ) :
                data.data.map((user, index) => (
                  <Tr className="with-hover">
                    <TdLink to={`/users/${user.referred_user.id}`}>
                      {((page - 1) * size) + (index + 1)}
                    </TdLink>
                    <TdLink to={`/users/${user.referred_user.id}`}>
                      {utils.getText(`${user.referred_user.first_name} ${user.referred_user.last_name}`)}
                    </TdLink>
                    <TdLink to={`/users/${user.referred_user.id}`}>
                      <Tag colorScheme={!user.kyc ? "red" : "green"}>
                        <TagLabel>{user.kyc ? "Verified" : "Unverified"}</TagLabel>
                      </Tag>
                    </TdLink>
                    <TdLink to={`/users/${user.referred_user.id}`}>
                      {user.qualified_txs}
                    </TdLink>
                    <TdLink to={`/users/${user.referred_user.id}`}>
                      {utils.formatDate(user.referred_user.created_at)}
                    </TdLink>
                  </Tr>
                ))
            }
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        pageCount={Math.ceil(data.pagination.total / data.pagination.per_page)}
        onPageChange={e => setPage(e.selected + 1)}
        forcePage={(data.pagination.current_page ?? 1) - 1}
        pagination={data.pagination}
        size={size}
        onSizeChange={setSize}
      />
    </div>
  )
}

export default ReferredUsers;
