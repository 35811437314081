import React from 'react';
import Header from "../../../components/Layout/Header";
import Stats from "./stats";
import PersonalTable from "./table";

interface IProps {
}

function UsersPersonalPage(props: IProps) {
  return (
    <div>
      <Header title="Users"/>

      <div className="grid grid-cols-1 gap-4 px-4">
        <Stats/>
        <PersonalTable/>
      </div>
    </div>
  );
}

export default UsersPersonalPage;
