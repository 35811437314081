import React from 'react';
import Header from "../../components/Layout/Header";
import TotalBalance from "./balance/totalBalance";
import Transactions from "./transactions";
import UsersChart from "./usersChart";
import TransactionRateChart from "./transactionRateChart";
import TopStates from "./topStates";
import TotalRevenue from "./totalRevenue";

interface IProps {
}

function Homepage(props: IProps) {
  return (
    <div>
      <Header title="Dashboard"/>

      <div className="grid grid-cols-3 gap-4 px-4">
        <div>
          <div className="grid gap-4">
            <TotalBalance/>
            <Transactions/>
            <UsersChart/>
          </div>
        </div>
        <div className="col-span-2">
          <div className="grid gap-4">
            <TotalRevenue/>
            <TransactionRateChart/>
            <TopStates/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
