import React from 'react';
import ReactPaginate, {ReactPaginateProps} from "react-paginate";
import {Button} from "@chakra-ui/react";
import {IconArrowLeft, IconArrowRight} from "@tabler/icons-react";
import {IPagination} from "../../types/common";
import SelectInput from "../SelectInput";

interface IProps extends ReactPaginateProps {
  pagination?: IPagination;
  size?: number;
  onSizeChange?: (e: number) => void;
}

function Pagination({pagination, onPageChange, size, onSizeChange, ...props}: IProps) {

  return (
    <div className="my-5 flex gap-4 items-center">
      <div className="flex-1">
        <ReactPaginate
          nextLabel={<Button>Next <IconArrowRight size={20}/></Button>}
          previousLabel={<Button><IconArrowLeft size={20}/> Previous</Button>}
          containerClassName="flex items-stretch gap-2"
          pageLinkClassName="bg-slate-200 hover:bg-slate-300 h-full flex px-2 items-center justify-center rounded-md apply-transition"
          activeLinkClassName="bg-zinc-700 hover:bg-zinc-700 text-white"
          pageRangeDisplayed={1}
          onPageChange={onPageChange}
          {...props}
        />
      </div>
      {
        !!size && !!onSizeChange && (
          <div>
            <SelectInput
              options={[10, 20, 50, 100].map(p => ({label: p, value: p}))}
              value={size}
              size="sm"
              onChange={(e) => {
                !!onPageChange && onPageChange({selected: 0});
                onSizeChange(Number(e.target.value));
              }}
            />
          </div>
        )
      }
      {
        !!pagination && (
          <div>
            <p>Showing {pagination.from} - {pagination.to} of {pagination.total}</p>
          </div>
        )
      }
    </div>
  );
}

export default Pagination;
