import React, { useState } from "react";
import Header from "../../../components/Layout/Header";
import { useQuery } from "react-query";
import crmService from "../../../services/crm.service";
import {
  Card,
  CardBody,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import TdLink from "../../../components/TdLink";
import Pagination from "../../../components/Pagination";

interface IProps {}
function CrmMessaagesPage(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { isLoading, data, isError } = useQuery(
    ["messages", page, size],
    () => crmService.getMessages(page, size),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  return (
    <div>
      <Header title="CRM Messages" />

      <div className="grid gap-4 px-4">
        <Card>
          <CardBody>
            <h6 className="font-semibold">CRM Groups</h6>

            <TableContainer mt={6}>
              <Table>
                <Thead>
                  <Th>Message</Th>
                  <Th>User group ID</Th>
                  <Th>Actions</Th>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <Tr>
                      <Td colSpan={3}>
                        <p className="text-center">Loading...</p>
                      </Td>
                    </Tr>
                  ) : isError ? (
                    <LoadingErrorContainer className="h-auto" />
                  ) : (
                    data?.data.data.map((message) => (
                      <Tr key={message.id} className="with-hover">
                        <Td className="max-w-xs overflow-hidden">
                          <p className="truncate">{message.message}</p>
                        </Td>
                        <TdLink to={`/crm/${message.user_group_id}`}>
                          {message.user_group_id}
                        </TdLink>
                        <Td>more</Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>

            {!!data?.pagination && (
              <Pagination
                pageCount={data.pagination.last_page}
                onPageChange={(e) => setPage(e.selected + 1)}
                forcePage={(data.pagination.current_page ?? 1) - 1}
                pagination={data.pagination}
                size={size}
                onSizeChange={setSize}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default CrmMessaagesPage;
