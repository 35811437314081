import React from 'react';
import {Card, CardBody, Spinner, Stat, StatLabel, StatNumber} from "@chakra-ui/react";
import {useQuery} from "react-query";
import userService from "../../../services/user.service";
import utils from "../../../utils/Utils";

interface IProps {
}

function Stats(props: IProps) {
  const {
    data: activeUsers,
    isLoading: activeUsersLoading
  } = useQuery(["users", "active"], () => userService.getAllUsersCount("active"))
  const {
    data: inactiveUsers,
    isLoading: inactiveUsersLoading
  } = useQuery(["users", "inactive"], () => userService.getAllUsersCount("inactive"))

  return (
    <div className="grid grid-cols-3 gap-4">
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>All Users</StatLabel>
            <StatNumber>
              {
                activeUsersLoading || inactiveUsersLoading
                  ? <Spinner size="sm"/>
                  : utils.numberWithCommas((activeUsers ?? 0) + (inactiveUsers ?? 0))
              }
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>Active Users</StatLabel>
            <StatNumber>
              {activeUsersLoading ? <Spinner size="sm"/> : utils.numberWithCommas(activeUsers)}
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stat>
            <StatLabel>Suspended Users</StatLabel>
            <StatNumber>
              {inactiveUsersLoading ? <Spinner size="sm"/> : utils.numberWithCommas(inactiveUsers)}
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>
    </div>
  );
}

export default Stats;
