import React, {useEffect, useMemo} from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import * as yup from "yup";
import {IRegister} from "../../types/auth.types";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import authService from "../../services/auth.service";
import utils from "../../utils/Utils";
import TextInput from "../../components/TextInput";
import {Button, useToast} from "@chakra-ui/react";
import isEmpty from "is-empty";

interface IProps {
}

function RegisterPage(props: IProps) {
  const navigate = useNavigate();
  const toast = useToast();
  const [params] = useSearchParams();
  const token = params.get("token");

  useEffect(() => {
    if (isEmpty(token)) {
      navigate("/auth", {replace: true});
    }
  }, [token, navigate]);

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    phone: yup.string().required("Required"),
    password: yup.string().required("Required")
  });

  const initialValues: IRegister = useMemo(() => ({
    token: token ?? "",
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    password: "",
  }), [token]);

  const onSubmit = (values: IRegister, helpers: FormikHelpers<IRegister>) => {
    authService.register(values)
      .then(res => {
        helpers.setSubmitting(false);
        toast({status: "success", title: "Registration successful, please login"})
        navigate("/auth");
      })
      .catch(err => utils.handleRequestError(err, helpers))
  }

  return (
    <div>
      <h5 className="font-semibold">Register</h5>
      <p className="text-slate-400 max-w-md">
        Enter your details to be part of Fagopay administrators
      </p>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({isSubmitting, isValid}) => (
          <Form className="grid grid-cols-1 gap-5 mt-6">
            <Field name="first_name">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field
            >
            <Field name="middle_name">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Middle Name"
                  placeholder="Middle Name"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field
            >
            <Field name="last_name">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field
            >
            <Field name="phone">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Phone Number"
                  placeholder="Phone Number"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field
            >
            <Field name="password">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Password"
                  placeholder="Password"
                  error={meta.touched && meta.error}
                  type="password"
                  autoComplete="current-password"
                  {...field}
                />
              )}
            </Field>

            <div>
              <Button
                size="lg"
                colorScheme="brand"
                className="w-full"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Register
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <p className="mt-6">Already have an account? <Link to="/auth"
                                                         className="text-primary">Login</Link></p>
    </div>
  );
}

export default RegisterPage;
