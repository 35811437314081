import {Page} from "../types/common";
import {IDailyLimit, IUserDetails, IUserProfile} from "../types/auth.types";
import apiInstance from "./api";
import {AxiosResponse} from "axios";

class UserService {

  searchUsers(filter: object, page: number = 1, size: number = 10): Promise<Page<IUserDetails>> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/users", {params: {page, per_page: size, ...filter}})
        .then((res: AxiosResponse<Page<IUserDetails>>) => {
          resolve(res.data);
        })
        .catch(err => reject(err))
    })
  }

  banUser({userId, ban}: { userId: string, ban: boolean }): Promise<IUserDetails> {
    return new Promise((resolve, reject) => {
      apiInstance.post(ban ? "/admin/users/ban" : "/admin/users/unban", {user_id: userId})
        .then((res: AxiosResponse<{ user: IUserDetails }>) => {
          resolve(res.data.user)
        })
        .catch(err => reject(err));
    })
  }

  lockUserTransactions({userId, lock}: { userId: string, lock: boolean }): Promise<string> {
    return new Promise((resolve, reject) => {
      apiInstance.post(lock ? "/admin/transactions/lock-transactions" : "/admin/transactions/unlock-transactions",
        {user_id: userId})
        .then((res: AxiosResponse<{ message: string }>) => {
          resolve(res.data.message)
        })
        .catch(err => reject(err));
    })
  }

  getUserDetails(userId?: string): Promise<IUserProfile | undefined> {
    return new Promise((resolve, reject) => {
      if (userId) {
        apiInstance.get(`/admin/users/profile/${userId}`)
          .then((res: AxiosResponse<{ data: IUserProfile }>) => {
            resolve(res.data.data);
          })
          .catch(err => reject(err));
      } else {
        resolve(undefined);
      }
    })
  }

  getUserDailyLimit(userId: string): Promise<IDailyLimit | undefined> {
    return new Promise((resolve, reject) => {
      if (userId) {
        apiInstance.get(`/admin/users/limit-today/${userId}`)
          .then((res: AxiosResponse<{ data: IDailyLimit }>) => {
            resolve(res.data.data);
          })
          .catch(err => reject(err));
      } else {
        resolve(undefined);
      }
    })
  }

  getAllUsersCount(status: string): Promise<any> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/users/count", {params: {status}})
        .then((res: AxiosResponse<{ count: number }>) => {
          resolve(res.data.count);
        })
        .catch(err => reject(err));
    })
  }

  getBvnDetails(userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      apiInstance.get(`/admin/users/${userId}/bvn-details`)
        .then((res) => {
          resolve(res.data.details);
        })
        .catch(err => reject(err));
    })
  }

  getGovtDetails(userId: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      apiInstance.get(`/admin/users/${userId}/govt-details`)
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  }

  changeTier(userId: string, tierId: string) {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/users/${userId}/update-tier/${tierId}`)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }
}

const userService = new UserService();
export default userService;
