import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {IconAdjustmentsAlt} from "@tabler/icons-react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import isEmpty from "is-empty";
import utils from "../../../utils/Utils";

interface IProps {
  setFilter?: (e: object) => void;
  filter?: object;
}

interface IFilterValues {
  search?: string;
  status?: "active" | "inactive";
}

function FilterPersonalTable({setFilter, filter}: IProps) {
  const {isOpen, onClose, onOpen} = useDisclosure();

  const initialValues: IFilterValues = filter ?? {}

  const onSubmit = (values: IFilterValues, helpers: FormikHelpers<IFilterValues>) => {
    !!setFilter && setFilter({
      search: !isEmpty(values.search) ? values.search : null,
      status: !isEmpty(values.status) ? values.status : null,
    });
    helpers.setSubmitting(false);
    onClose();
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({isSubmitting, handleSubmit, values}) => (
        <Form>
          <div>
            <div className="flex gap-3">
              <div className="w-[300px]">
                <Field name="search">
                  {({field}: FieldProps) => (
                    <TextInput
                      placeholder="Search by name, id, email, acc. no..."
                      type="text"
                      size="md"
                      {...field}
                    />
                  )}
                </Field>
              </div>

              <Button type="button" onClick={onOpen} className="relative">
                <IconAdjustmentsAlt className="mr-1" size={20}/> Filter
                {
                  utils.isEmptyValues(values) && (
                    <div
                      className="absolute -top-2 -right-2 w-5 h-5 bg-red-500 border-4 border-white rounded-full"/>
                  )
                }
              </Button>

              <Button colorScheme='brand' type="submit" isLoading={isSubmitting}>
                Apply
              </Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay/>
              <ModalContent>
                <ModalHeader>Filter</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                  <div className="grid grid-cols-1 gap-6">
                    <Field name="status">
                      {({field}: FieldProps) => (
                        <SelectInput
                          label="Status"
                          placeholder="Status"
                          options={[
                            {
                              label: "Active",
                              value: "active",
                            },
                            {
                              label: "Inactive",
                              value: "inactive",
                            },
                          ]}
                          {...field}
                        />
                      )}
                    </Field>

                    <div className="grid grid-cols-2 gap-2">
                      <p className="col-span-2 font-bold">Filter by date joined</p>
                      <Field name="startDate">
                        {({field}: FieldProps) => (
                          <TextInput
                            label="From Date"
                            type="date"
                            {...field}
                          />
                        )}
                      </Field>

                      <Field name="toDate">
                        {({field}: FieldProps) => (
                          <TextInput
                            label="To Date"
                            type="date"
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button variant='ghost' colorScheme="red" type="button" onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme='brand'
                    type="submit"
                    ml={3}
                    isLoading={isSubmitting}
                    onClick={() => handleSubmit()}
                  >
                    Apply
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default FilterPersonalTable;
