import React from 'react';
import Header from "../../../components/Layout/Header";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import transactionService from "../../../services/transaction.service";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import {AxiosError} from "axios";
import {Card, CardBody, Tag, TagLabel} from "@chakra-ui/react";
import utils from "../../../utils/Utils";
import {IFullTransaction} from "../../../types/transaction.types";
import UserData from "./userData";

interface IProps {
}

function IndividualTransactionPage(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {
    isLoading,
    isLoadingError,
    error,
    data,
  } = useQuery<IFullTransaction, AxiosError<any>>(["transaction", id], () => transactionService.getTransactionDetails(id!));

  return (
    <div>
      <Header title={`Transaction: ${id}`}/>

      {
        isLoading ? (
          <LoadingContainer/>
        ) : isLoadingError ? (
          <LoadingErrorContainer errorMessage={error?.response?.data?.data?.error}/>
        ) : (
          <div className="grid gap-4 px-4">
            <div className="grid grid-cols-2 gap-4">
              <Card>
                <CardBody>
                  <p className="small">Amount</p>
                  <h4 className="font-semibold">
                    ₦ {utils.numberWithCommas(data?.transaction.transaction_type === "credit" ? data?.transaction.inflow.toFixed(2) : data?.transaction.outflow.toFixed(2))}
                  </h4>
                  {
                    !!data?.transaction.created_at && (
                      <p>
                        {utils.formatDate(new Date(data?.transaction.created_at), true)}
                      </p>
                    )
                  }

                  <div className="mt-8">
                    <p className="small">Description</p>
                    <p>{data?.transaction.description}</p>
                  </div>

                  <div className="mt-4">
                    <p className="small">Session</p>
                    <p>{data?.transaction.session_id}</p>
                  </div>


                  <div className="grid grid-cols-2 gap-4 mt-6">
                    <div className="mt-4">
                      <p className="small">To-From</p>
                      <h6 className="font-semibold">{data?.transaction.to_from}</h6>
                    </div>

                    <div className="mt-4">
                      <p className="small">Fee</p>
                      <h6 className="font-semibold text-red-500">
                        ₦ {utils.numberWithCommas(data?.transaction.charges_fee.toFixed(2))}
                      </h6>
                    </div>

                    <div>
                      <p className="small">Type</p>
                      <Tag
                        colorScheme={data?.transaction.transaction_type === "debit" ? "red" : "green"}>
                        <TagLabel>{data?.transaction.transaction_type.toUpperCase()}</TagLabel>
                      </Tag>
                    </div>

                    <div>
                      <p className="small">Status</p>
                      <Tag
                        colorScheme={
                          data?.transaction.transaction_status === "success" ? "green" :
                            data?.transaction.transaction_status === "pending" ? "gray" :
                              "red"
                        }>
                        <TagLabel>{data?.transaction.transaction_status.toUpperCase()}</TagLabel>
                      </Tag>
                    </div>

                    <div>
                      <p className="small">Reference</p>
                      <h6 className="font-semibold">{data?.transaction.reference}</h6>
                    </div>

                    <div>
                      <p className="small">Category</p>
                      <h6 className="font-semibold">{data?.transaction.category}</h6>
                    </div>

                    <div>
                      <p className="small">Balance Before</p>
                      <h6 className="font-semibold">
                        ₦ {utils.numberWithCommas(data?.transaction.balance_before.toFixed(2))}
                      </h6>
                    </div>

                    <div>
                      <p className="small">Balance After</p>
                      <h6 className="font-semibold">
                        ₦ {utils.numberWithCommas(data?.transaction.balance_after.toFixed(2))}
                      </h6>
                    </div>

                  </div>
                </CardBody>
              </Card>

              <UserData data={data?.user!}/>
            </div>
          </div>
        )
      }

    </div>
  );
}

export default IndividualTransactionPage;
