import isEmpty from "is-empty";
import {format} from "date-fns";
import {FormikHelpers} from "formik";
import toast from "react-hot-toast";

class Utils {

  delay(ms?: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, ms ?? 3000)
    });
  }

  public handleRequestError(err: any, helpers?: FormikHelpers<any>) {
    !!helpers && helpers.setSubmitting(false);
    if (err?.response?.data?.data) {
      !!helpers && helpers.setErrors(err.response.data.data);
    }
    if (err?.response?.data?.data?.error) {
      toast.error(err.response.data.data.error);
    } else {
      toast.error(err.message)
    }
  }

  numberWithCommas(val: string | number | undefined) {
    const x = (isEmpty(val) || !val) ? "0" : val.toString().replace(/,/g, "");
    const xArray = x.split(".");
    const f = xArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (xArray.length > 1) {
      return f + "." + xArray[1];
    } else {
      return f;
    }
  }

  formatDate(date: Date | number, withTime?: boolean) {
    try {
      return format(new Date(date), withTime ? "dd MMM. yyyy hh:mm aa" : "dd MMM. yyyy");
    } catch (e) {
      console.log(e);
      return "-";
    }
  }

  getText(text?: string) {
    return isEmpty(text?.replace(/\s+/, "")) ? "-" : text;
  }

  isEmptyValues(obj: object): boolean {
    return Object.values(obj).some(v => !isEmpty(v));
  }
}

const utils = new Utils();
export default utils;
