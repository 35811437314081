import React, {useCallback, useMemo} from 'react';
import {Card, CardBody, Divider, IconButton} from "@chakra-ui/react";
import {IconDotsVertical} from "@tabler/icons-react";

interface IProps {
}

function TopStates(props: IProps) {
  const data = [
    {
      title: "Lagos",
      value: 105,
    },
    {
      title: "Katsina",
      value: 225,
    },
    {
      title: "Oyo",
      value: 168,
    },
    {
      title: "FCT",
      value: 189,
    },
    {
      title: "Kano",
      value: 94,
    },
  ]

  const highest = useMemo(() => Math.max(...data.map(d => d.value)), [data]);

  const getPercentage = useCallback((value: number) => {
    return value / highest * 100;
  }, [highest]);

  const getOpacity = useCallback((value: number) => {
    return 0.3 + (value / highest * 0.7)
  }, [highest]);

  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="px-6 py-4 flex items-center gap-4">
          <div className="flex-1">
            <h6 className="font-semibold">Top States</h6>
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              aria-label="more"
              icon={<IconDotsVertical/>}
              variant="ghost"
              size="sm"
            />
          </div>
        </div>

        <Divider/>

        <div className="grid">
          {
            data.map((d, i) => (
              <div
                key={i}
                className="w-full h-12 relative z-[1] flex items-center justify-between px-4 text-white">
                <div
                  className="absolute h-full left-0 top-0 z-[-1]"
                  style={{
                    width: `${getPercentage(d.value)}%`,
                    backgroundColor: `rgba(198, 28, 38, ${getOpacity(d.value)})`
                  }}
                />
                <p className="bg-primary px-2 py-1 rounded leading-none">{d.title}</p>
                <p className="bg-primary px-2 py-1 rounded leading-none small">{d.value}</p>
              </div>
            ))
          }
        </div>

      </CardBody>
    </Card>
  );
}

export default TopStates;
