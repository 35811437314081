import React, {useMemo} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {IBusinessDetails} from "../../../types/business.types";
import {useParams} from "react-router-dom";

interface IProps {
  userId?: string;
}

function BusinessBvnDetails({userId}: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {id} = useParams<{ id: string }>();
  const {data} = useQuery<IBusinessDetails>(["business", id]);

  const details = useMemo(() => {
    if (!!data && !!data.bvn_detail) {
      return Object.keys(data.bvn_detail).map(k => ({key: k, value: data.bvn_detail[k]}));
    }
    return [];
  }, [data]);

  return (
    <>
      <Button onClick={onOpen}>BVN Details</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>BVN Details</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              {
                !!data && !!data.bvn_detail && (
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                      <p className="font-semibold">BVN Image</p>

                      <img src={data.bvn_detail.bvn_image} alt="BVN Image"
                           className="w-full max-w-xs h-auto mt-4"/>
                    </div>
                    <div>
                      <p className="font-semibold">Liveliness Image</p>

                      <img src={data.bvn_detail.liveliness_image} alt="Liveliness Image"
                           className="w-full max-w-xs h-auto mt-4"/>
                    </div>
                  </div>
                )
              }
              <TableContainer>
                <Table>
                  <Thead>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Thead>
                  <Tbody>
                    {
                      details.map((k, i) => (
                        <Tr key={i}>
                          <Td>{k.key}</Td>
                          <Td>{k.value}</Td>
                        </Tr>
                      ))
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessBvnDetails;
