import React from 'react';
import {Card, CardBody, Stat, StatHelpText, StatLabel, StatNumber} from "@chakra-ui/react";
import {useQuery} from "react-query";
import transactionService from "../../services/transaction.service";
import {AxiosError} from "axios";
import {ITransactionOverview} from "../../types/transaction.types";

interface IProps {
}

function TransactionsStats(props: IProps) {
  const {
    data: total,
    isLoading: totalLoading,
    isLoadingError: totalLoadingError,
    error: totalError
  } = useQuery<number, AxiosError<any>>(["transactions", "totalCount"], transactionService.getTotalTransactions, {
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const {
    data: overview,
    isLoading,
    isLoadingError,
    error
  } = useQuery(["transactions", "overview"], transactionService.getOverview, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return (
    <div className="grid grid-cols-3 gap-4">
      {
        // @ts-ignore
        !!overview && Object.keys(overview).map((key: keyof ITransactionOverview) => (
          <Card key={key}>
            <CardBody>
              <Stat>
                <StatLabel className="capitalize">{key}</StatLabel>
                <StatNumber>
                  {`₦ ${overview[key].total}`}
                </StatNumber>
                <StatHelpText>
                  <span
                    className="font-semibold">Successful:</span> {overview?.[key].successful}
                </StatHelpText>
                <StatHelpText>
                  <span
                    className="font-semibold">Failed:</span> {overview?.[key].failed}
                </StatHelpText>
                <StatHelpText>
                  <span
                    className="font-semibold">Pending:</span> {overview?.[key].pending}
                </StatHelpText>
              </Stat>
            </CardBody>
          </Card>
        ))
      }
    </div>
  );
}

export default TransactionsStats;
