import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {IBusinessDetails} from "../../../types/business.types";
import utils from "../../../utils/Utils";
import Pagination from "../../../components/Pagination";

interface IProps {
}

function BusinessInvoices(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {data} = useQuery<IBusinessDetails>(["business", id]);

  return (
    <>
      <div>
        <h6 className="font-semibold">Invoices</h6>
        <p className="small text-slate-500">Business Invoices</p>
        <Button onClick={onOpen} mt={4}>See all</Button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Invoices</ModalHeader>
          <ModalBody>
            <TableContainer className="mt-4">
              <Table>
                <Thead>
                  <Th>Invoice no.</Th>
                  <Th>Reference</Th>
                  <Th>Customer</Th>
                  <Th>Payment mode</Th>
                  <Th>Created date</Th>
                  <Th>Due date</Th>
                  <Th>Status</Th>
                  <Th>Tax</Th>
                  <Th>Discount</Th>
                  <Th>Subtotal</Th>
                  <Th>Total</Th>
                  <Th>Amount paid</Th>
                </Thead>
                <Tbody>
                  {
                    data?.invoices?.map(invoice => (
                      <Tr key={invoice.id} className="cursor-pointer">
                        <Td>{utils.getText(invoice.invoice_number)}</Td>
                        <Td>{utils.getText(invoice.reference)}</Td>
                        <Td>{utils.getText(invoice.customer_id)}</Td>
                        <Td>{utils.getText(invoice.payment_mode ?? "")}</Td>
                        <Td>{utils.formatDate(new Date(invoice.created_at))}</Td>
                        <Td>{utils.formatDate(new Date(invoice.due_date))}</Td>
                        <Td>{invoice.status}</Td>
                        <Td>
                          {utils.numberWithCommas(Number(invoice.tax_amount).toFixed(2))}{" "}
                          ({utils.numberWithCommas(Number(invoice.tax_rate).toFixed(2))}%)
                        </Td>
                        <Td>
                          {utils.numberWithCommas(Number(invoice.discount_amount).toFixed(2))}{" "}
                          ({utils.numberWithCommas(Number(invoice.discount_rate).toFixed(2))}%)
                        </Td>
                        <Td>{utils.numberWithCommas(Number(invoice.subtotal).toFixed(2))}</Td>
                        <Td>{utils.numberWithCommas(Number(invoice.total).toFixed(2))}</Td>
                        <Td>{utils.numberWithCommas(Number(invoice.amount_paid).toFixed(2))}</Td>
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>

            <Pagination
              pageCount={1}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessInvoices;
