import React from 'react';
import {Button, Card, CardBody, Divider} from "@chakra-ui/react";
import {IconReplaceFilled} from "@tabler/icons-react";

interface IProps {
}

function NotificationBody(props: IProps) {
  return (
    <div>
      <p>You have 6 new notifications</p>
      <div className="mt-6 grid">
        <Divider/>
        {
          [...Array(16)].map((_, index) => (
            <>
              <Card size="sm" shadow="none" rounded="none" key={`card-${index}`}>
                <CardBody>
                  <div className="flex items-start gap-4">
                    <div
                      className="w-14 aspect-square rounded-full bg-purple-100 flex items-center justify-center"
                    >
                      <IconReplaceFilled className="text-purple-500"/>
                    </div>
                    <div className="w-full">
                      <p className="small font-semibold text-purple-500">Airtime swap</p>
                      <p>John Doe requested for airtime swap</p>
                      <div className="mt-2">
                        <Button size="sm">Learn more</Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Divider key={`divider-${index}`}/>
            </>
          ))
        }
      </div>
    </div>
  );
}

export default NotificationBody;
