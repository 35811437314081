import React, {useCallback, useEffect, useState} from 'react';
import Header from "../../components/Layout/Header";
import TransactionsStats from "./transactionsStats";
import TransactionsTable from "../../components/TransactionsTable";
import {useQuery} from "react-query";
import isEmpty from "is-empty";
import transactionService from "../../services/transaction.service";
import LoadingContainer from "../../components/LoadingContainer";
import {ITransaction} from "../../types/transaction.types";

interface IProps {
}

function TransactionsPage(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filterApplied, setFilterApplied] = useState({});

  useEffect(() => {
    setPage(1);
  }, [filterApplied]);

  const {data, isLoading} = useQuery(
    ["transactions", page, size, isEmpty(filterApplied) ? "NO_FILTER" : JSON.stringify(filterApplied)],
    () => transactionService.getAllTransactions(page, size, filterApplied),
    {keepPreviousData: true}
  );

  const fetchAllForExport = useCallback(async () => {
    if (!data?.pagination) return [];

    try {
      let transactions: ITransaction[] = [];
      let fetched = 1;
      const size = 500;
      const total = Math.ceil(data?.pagination.total / size);

      while (fetched <= total) {
        const tran = await transactionService.getAllTransactions(fetched, size, filterApplied);
        transactions = transactions.concat(tran.data);
        fetched += 1;
      }
      return transactions;
    } catch (e) {
      throw new Error("Unable to export transactions");
    }
  }, [data?.pagination, filterApplied]);

  return (
    <div>
      <Header title="Transactions"/>

      <div className="grid grid-cols-1 gap-4 px-4">
        <TransactionsStats/>
        {
          isLoading ? (
            <LoadingContainer/>
          ) : !data ? (
            <div></div>
          ) : (
            <TransactionsTable
              data={data.data}
              pagination={data.pagination}
              setPage={setPage}
              applyFilter={setFilterApplied}
              appliedFilter={filterApplied}
              size={size}
              setSize={setSize}
              fetchAllForExport={fetchAllForExport}
            />
          )
        }
      </div>
    </div>
  );
}

export default TransactionsPage;
