import React, {useMemo} from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import {IUserProfile} from "../../../types/auth.types";
import {AxiosError} from "axios";
import tierService from "../../../services/tier.service";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import SelectInput from "../../../components/SelectInput";
import userService from "../../../services/user.service";

interface IProps {
}

function Tier(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {
    isLoading,
    data
  } = useQuery<IUserProfile | undefined, AxiosError<any>>(
    ["user", id]
  );

  return (
    <Card>
      <CardBody>
        <div className="flex flex-wrap gap-4 items-center mb-4">
          <h6 className="flex-1 font-semibold">Tier</h6>
          <div>
            <ChangeTier/>
          </div>
        </div>

        <h3>{isLoading ? <Spinner/> : data?.profile.account_tier ?? "-"}</h3>
      </CardBody>
    </Card>
  );
}

function ChangeTier() {
  const {id} = useParams<{ id: string }>();
  const {
    data
  } = useQuery<IUserProfile | undefined, AxiosError<any>>(
    ["user", id]
  );
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    data: tiers,
  } = useQuery("tiers", tierService.getTiers, {refetchOnWindowFocus: false});

  const tiersOptions = useMemo(() => {
    if (tiers) {
      return tiers.map(t => ({
        label: t.name,
        value: t.id
      }))
    } else {
      return []
    }
  }, [tiers]);

  const initialValues: { tier: string } = useMemo(() => {
    if (tiers && data?.profile.account_tier) {
      return {
        tier: tiers.find(t => t.name === data?.profile.account_tier)?.id ?? ""
      }
    }
    return {
      tier: "",
    }
  }, [tiers, data?.profile.account_tier])

  const onSubmit = (values: { tier: string }, helpers: FormikHelpers<{ tier: string }>) => {
    userService.changeTier(data?.profile.id!, values.tier)
      .then(async () => {
        await queryClient.invalidateQueries(["user", id]);
        toast({status: "success", title: "Tier updated successfully"})
        onClose();
      })
      .catch(err => {
        helpers.setSubmitting(false);
        toast({status: "error", title: err.response?.data?.data?.error ?? "Unable to update tier"});
      })
  }

  return (
    <>
      <Button onClick={onOpen}>Change</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>
            Change Tier
            <ModalCloseButton/>
          </ModalHeader>

          <ModalBody>
            <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
              {({isSubmitting}) => (
                <Form className="grid gap-4 pb-6">
                  <Field name="tier">
                    {({field}: FieldProps) => (
                      <SelectInput label="Choose tier" options={tiersOptions} {...field}/>
                    )}
                  </Field>

                  <div>
                    <Button colorScheme="brand" className="w-full"
                            isLoading={isSubmitting} type="submit">Save</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Tier;
