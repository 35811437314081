import React from 'react';
import {Card, CardBody, Divider, IconButton} from "@chakra-ui/react";
import SelectInput from "../../components/SelectInput";
import {IconDotsVertical} from "@tabler/icons-react";
import RevenueChart from "./revenueChart";

interface IProps {
}

function TotalRevenue(props: IProps) {
  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="px-6 py-4 flex items-center gap-4">
          <div className="flex-1">
            <h6 className="font-semibold">Total Revenue</h6>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-fit">
              <SelectInput options={[]} placeholder="Duration" size="sm"/>
            </div>
            <IconButton
              aria-label="more"
              icon={<IconDotsVertical/>}
              variant="ghost"
              size="sm"
            />
          </div>
        </div>
        <Divider/>
        <RevenueChart/>
      </CardBody>
    </Card>
  );
}

export default TotalRevenue;
