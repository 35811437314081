import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {IconAdjustmentsAlt, IconTrash} from "@tabler/icons-react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import {useQuery} from "react-query";
import transactionService from "../../services/transaction.service";

interface IProps {
  applyFilter?: (filter: Map<string, any>) => void;
  appliedFilter?: any;
}

function FilterTransactionsTable({applyFilter, appliedFilter}: IProps) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const {
    data: categories,
  } = useQuery("transactionCategories", transactionService.getTransactionCategories);

  const initialValues = appliedFilter ?? {}

  const onSubmit = (values: any, helpers: FormikHelpers<any>) => {
    if (applyFilter) {
      applyFilter(values);
    }
    helpers.setSubmitting(false);
    onClose();
  }

  const clearFilter = () => {
    if (applyFilter) {
      applyFilter(new Map<string, any>());
    }
    onClose();
  }

  return (
    <div>
      <Button onClick={onOpen}>
        <IconAdjustmentsAlt className="mr-1" size={20}/> Filter
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
            {({isSubmitting}) => (
              <Form>
                <ModalHeader>Filter</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                  <div className="grid grid-cols-1 gap-6">
                    <Field name="search">
                      {({field}: FieldProps) => (
                        <TextInput
                          label="Search by Reference, ID, User"
                          placeholder="Reference, ID, User"
                          type="text"
                          {...field}
                        />
                      )}
                    </Field>

                    <Field name="type">
                      {({field}: FieldProps) => (
                        <SelectInput
                          label="Transaction type"
                          placeholder="Transaction type"
                          options={[
                            {
                              label: "CREDIT",
                              value: "credit",
                            },
                            {
                              label: "DEBIT",
                              value: "debit",
                            },
                          ]}
                          {...field}
                        />
                      )}
                    </Field>

                    <Field name="category">
                      {({field}: FieldProps) => (
                        <SelectInput
                          label="Category"
                          placeholder="Category"
                          options={categories?.map(c => ({
                            label: c,
                            value: c
                          })) ?? []}
                          {...field}
                        />
                      )}
                    </Field>

                    <Field name="status">
                      {({field}: FieldProps) => (
                        <SelectInput
                          label="Transaction status"
                          placeholder="Transaction status"
                          options={[
                            {
                              label: "Pending",
                              value: "pending",
                            },
                            {
                              label: "Success",
                              value: "success",
                            },
                            {
                              label: "Failed",
                              value: "failed",
                            },
                          ]}
                          {...field}
                        />
                      )}
                    </Field>

                    <div className="grid grid-cols-2 gap-2">
                      <p className="col-span-2 font-bold">Filter by date created</p>
                      <Field name="start_date">
                        {({field}: FieldProps) => (
                          <TextInput
                            label="From Date"
                            type="date"
                            {...field}
                          />
                        )}
                      </Field>

                      <Field name="end_date">
                        {({field}: FieldProps) => (
                          <TextInput
                            label="To Date"
                            type="date"
                            {...field}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button variant='ghost' colorScheme="red" type="button" onClick={onClose}
                          className="mr-auto">
                    Close
                  </Button>
                  <Button variant='outline' colorScheme="red" type="button" onClick={clearFilter}>
                    <IconTrash size={20} className="mr-2"/>
                    Clear Filters
                  </Button>
                  <Button colorScheme='brand' type="submit" ml={3} isLoading={isSubmitting}>
                    Apply
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default FilterTransactionsTable;
