import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import userService from "../../../services/user.service";

interface IProps {
  userId?: string;
}

function GovtIdDetails({userId}: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {data} = useQuery(["user", "govtId", userId], () => userService.getGovtDetails(userId ?? ""));

  return (
    <>
      <Button onClick={onOpen}>Govt. ID Details</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Govt. ID Details</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              {!!data && data.toString()}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default GovtIdDetails;
