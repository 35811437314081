import React, {useCallback, useEffect, useState} from 'react';
import {
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {
  IconDotsVertical,
  IconInfoSquareRounded,
  IconUserCheck,
  IconUserOff
} from "@tabler/icons-react";
import Pagination from "../../../components/Pagination";
import FilterPersonalTable from "./filterPersonalTable";
import {useMutation, useQuery, useQueryClient} from "react-query";
import userService from "../../../services/user.service";
import utils from "../../../utils/Utils";
import isEmpty from "is-empty";
import {IUserDetails} from "../../../types/auth.types";
import {Page} from "../../../types/common";
import {Link} from "react-router-dom";
import LoadingContainer from "../../../components/LoadingContainer";
import ExportUsers from "./exportUsers";
import TdLink from "../../../components/TdLink";

interface IProps {
}

function PersonalTable(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filterApplied, setFilterApplied] = useState<object>({});

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterApplied)]);

  const queryClient = useQueryClient();
  const {data, isLoading} = useQuery(
    ["personal-users", page, size, isEmpty(filterApplied) ? "NO_FILTER" : JSON.stringify(filterApplied)],
    () => userService.searchUsers(filterApplied, page, size),
    {keepPreviousData: true}
  );

  const {mutate} = useMutation(userService.banUser, {
    onSuccess(newUserData) {
      if (data) {
        const oldData: Page<IUserDetails> = {...data};
        const userIndex = oldData.data.findIndex(u => u.id === newUserData.id);
        if (userIndex >= 0) {
          oldData.data[userIndex] = newUserData;
          queryClient.setQueryData(["personal-users", page], oldData)
        }
      }
    }
  });

  const fetchAllForExport = useCallback(async () => {
    if (!data?.pagination) return [];

    try {
      let users: IUserDetails[] = [];
      let fetched = 1;
      const size = 100;
      const total = Math.ceil(data?.pagination.total / size);

      while (fetched <= total) {
        const usr = await userService.searchUsers(filterApplied, fetched, size);
        users = users.concat(usr.data);
        fetched += 1;
      }
      return users;
    } catch (e) {
      throw new Error("Unable to export transactions");
    }
  }, [data?.pagination, filterApplied]);

  return (
    <Card>
      <CardBody>
        <div className="flex items-center flex-wrap mb-4 gap-4">
          <h5 className="font-semibold">All Users</h5>
          <FilterPersonalTable setFilter={setFilterApplied} filter={filterApplied}/>
          <ExportUsers fetchAllForExport={fetchAllForExport} className="ml-auto"/>
        </div>

        {
          isLoading ? (
            <LoadingContainer/>
          ) : (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>S/N</Th>
                    <Th>First Name</Th>
                    <Th>Middle Name</Th>
                    <Th>Last Name</Th>
                    <Th>Email</Th>
                    <Th>Primary bank acc.</Th>
                    <Th>Status</Th>
                    <Th>BVN</Th>
                    <Th>KYC</Th>
                    <Th>Date joined</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    data?.data.map((user, index) => (
                      <Tr key={`tr-${user.id}`} className="with-hover">
                        <TdLink to={`/users/${user.id}`}>
                          {((page - 1) * size) + (index + 1)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.first_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.middle_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.last_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.email)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.getText(user.accountdetail?.bank_name)}
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag colorScheme={user.is_restricted ? "red" : "green"}>
                            <TagLabel>{user.is_restricted ? "Restricted" : "Active"}</TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag colorScheme={!user.bvn_verified ? "red" : "green"}>
                            <TagLabel>{user.bvn_verified ? "Verified" : "Unverified"}</TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          <Tag colorScheme={!user.kyc_verified ? "red" : "green"}>
                            <TagLabel>{user.kyc_verified ? "Verified" : "Unverified"}</TagLabel>
                          </Tag>
                        </TdLink>
                        <TdLink to={`/users/${user.id}`}>
                          {utils.formatDate(new Date(user.created_at))}
                        </TdLink>
                        <Td>
                          <Menu isLazy>
                            <MenuButton
                              as={IconButton}
                              icon={<IconDotsVertical size={20}/>}
                              size="sm"
                              aria-label="Actions"
                              isRound
                            />
                            <MenuList>
                              {
                                user.is_restricted ? (
                                  <MenuItem
                                    color="green.500"
                                    onClick={() => mutate({userId: user.id, ban: false})}
                                  >
                                    <IconUserCheck className="mr-2" size={20}/>
                                    Activate
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    color="red.500"
                                    onClick={() => mutate({userId: user.id, ban: true})}
                                  >
                                    <IconUserOff className="mr-2" size={20}/>
                                    Deactivate
                                  </MenuItem>
                                )
                              }
                              <MenuItem as={Link} to={`/users/${user.id}`}>
                                <IconInfoSquareRounded className="mr-2" size={20}/>
                                See more
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))
                  }

                </Tbody>
              </Table>
            </TableContainer>
          )
        }
        {
          data?.pagination &&
            <Pagination
                pageCount={Math.ceil(data.pagination.total / data.pagination.per_page)}
                onPageChange={e => setPage(e.selected + 1)}
                forcePage={(data.pagination.current_page ?? 1) - 1}
                pagination={data.pagination}
                size={size}
                onSizeChange={setSize}
            />
        }

      </CardBody>
    </Card>
  );
}

export default PersonalTable;
