import React from 'react';
import Header from "../../../../components/Layout/Header";
import TransactionVolume from "./transactionVolume";
import TransactionAnalysis from "./transactionAnalysis";
import Settings from "./settings";
import ThirdParty from "./thirdParty";
import TransactionsTable from "../../../../components/TransactionsTable";
import {IconArrowNarrowRight} from "@tabler/icons-react";

interface IProps {
}

function CC_Personal_BankTransferPage(props: IProps) {
  return (
    <div>
      <Header>
        CC <IconArrowNarrowRight className="mx-2"/> Personal <IconArrowNarrowRight
        className="mx-2"/> Bank Transfer
      </Header>

      <div className="grid gap-4 px-4">
        <div className="grid grid-cols-3 gap-4">
          <TransactionVolume type="Bank Transfer"/>

          <div className="grid gap-4">
            <TransactionAnalysis/>

            <ThirdParty/>
          </div>

          <Settings/>
        </div>

        <TransactionsTable data={[]}/>
      </div>
    </div>
  );
}

export default CC_Personal_BankTransferPage;
