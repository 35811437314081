import React from 'react';
import {IconPencil} from "@tabler/icons-react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import {IReferralCategory} from "../../../types/referral.types";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import TextInput from "../../../components/TextInput";
import TextAreaInput from "../../../components/TextAreaInput";
import referralService from "../../../services/referral.service";
import utils from "../../../utils/Utils";
import {useQueryClient} from "react-query";
import toast from "react-hot-toast";

interface IProps {
  data: IReferralCategory
}

function ReferralCategoryModal({data}: IProps) {
  const queryClient = useQueryClient();
  const {isOpen, onClose, onOpen} = useDisclosure();

  const validationSchema = yup.object().shape({});

  const initialValues: IReferralCategory = data;

  const onSubmit = (values: IReferralCategory, helpers: FormikHelpers<IReferralCategory>) => {
    referralService.updateReferralCategory(values)
      .then(async () => {
        await queryClient.invalidateQueries("referralCategories");
        toast.success("Updated successfully");
        onClose();
      })
      .catch(err => utils.handleRequestError(err, helpers));
  }

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        <IconPencil size={20}/>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Edit Category</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({isSubmitting, isValid}) => (
                <Form className="grid gap-4 pb-4">
                  <Field name="name">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Name"
                        placeholder="Name"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="description">
                    {({field, meta}: FieldProps) => (
                      <TextAreaInput
                        label="Description"
                        placeholder="Description"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="amount_per_point">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Amount per point"
                        placeholder="Amount per point"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="base_withdraw_amount">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Withdraw amount"
                        placeholder="Withdraw amount"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="referrer_bonus">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Referral bonus"
                        placeholder="Referral bonus"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="referred_person_bonus">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Referral bonus person"
                        placeholder="Referral bonus person"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <div className="flex gap-3 mt-4">
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                      colorScheme="brand"
                    >
                      Save
                    </Button>
                    <Button type="button" onClick={onClose}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReferralCategoryModal;
