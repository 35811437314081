import React from 'react';
import {IconSquareRoundedX, IconSquareRoundedXFilled} from "@tabler/icons-react";
import {
  Button,
  MenuItem,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {useQueryClient} from "react-query";
import airtimeCashService from "../../../../services/airtimeCash.service";
import {IAirtimeCashTransaction} from "../../../../types/airtimeCash.types";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import TextAreaInput from "../../../../components/TextAreaInput";

interface IProps {
  transaction: IAirtimeCashTransaction;
  page: number;
}

function DeclineTrans({transaction, page}: IProps) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const validationSchema = yup.object().shape({
    message: yup.string().required("Required"),
  })

  const initialValues: { message: string } = {
    message: "",
  }

  const onSubmit = (values: { message: string }, helpers: FormikHelpers<{ message: string }>) => {
    airtimeCashService.declineTransaction(transaction.id, values.message)
      .then(async res => {
        helpers.setSubmitting(false);
        toast({status: "success", title: "Declined successfully"});
        await queryClient.invalidateQueries(["pendingAirtimeToCash", page])
        onClose();
      })
      .catch(err => {
        toast({status: "error", title: "An error occurred"});
        helpers.setSubmitting(false);
      })
  }

  return (
    <MenuItem type="button" onClick={onOpen} color="red">
      <IconSquareRoundedXFilled size={20} className="mr-2"/>
      Decline

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({isSubmitting, isValid}) => (
              <Form>
                <div className="p-4 flex flex-col items-center">
                  <IconSquareRoundedX size={100} strokeWidth={1} className="text-red-500"/>
                  <h6 className="font-semibold text-center mt-4">Decline</h6>
                  <p>Are you sure you want to decline</p>

                  <Field name="message">
                    {({field, meta}: FieldProps) => (
                      <div className="w-full my-4">
                        <TextAreaInput
                          label="Message"
                          placeholder="Message"
                          error={meta.touched && meta.error}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>

                  <div className="w-full grid grid-cols-2 gap-4 mt-6">
                    <Button type="button" onClick={onClose} isDisabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button colorScheme="red" type="submit" isLoading={isSubmitting}
                            isDisabled={!isValid}>
                      Decline
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </MenuItem>
  );
}

export default DeclineTrans;
