import React from 'react';
import {Link} from "react-router-dom";
import {TableCellProps, Td} from "@chakra-ui/react";

interface IProps extends TableCellProps {
  to: string;
  children: React.ReactNode;
}

function TdLink({to, children, className, ...props}: IProps) {
  return (
    <Td className={`with-link ${className}`} {...props}>
      <Link to={to}>
        {children}
      </Link>
    </Td>
  );
}

export default TdLink;
