import React, {useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {INavItem} from "../../../types/common";
import {IconChevronRight} from "@tabler/icons-react";
import isEmpty from "is-empty";
import {AnimatePresence, motion} from "framer-motion"
import {useQuery} from "react-query";
import {IAdminDetails} from "../../../types/auth.types";
import adminService from "../../../services/admin.service";
import {AdminRoleType} from "../../../types/admin.types";

interface IProps extends INavItem {
  isSub?: boolean;
}

function NavItem({name, path, icon, sub, isSub}: IProps) {
  const [open, setOpen] = useState(false);
  const {data} = useQuery<IAdminDetails>("userDetails");

  const subNav = useMemo(() => {
    if (data && sub) {
      const role = adminService.getRole(data).toLowerCase();
      return sub?.filter(s => s.roles === undefined || s.roles.includes(role as AdminRoleType))
    }
    return undefined;
  }, [sub, data])

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (subNav) {
      if (path === "/") {
        e.preventDefault();
      }
      setOpen(prev => !prev);
    }
  }

  return (
    <div>
      <Link
        to={path ?? ""}
        className="flex gap-4 items-center h-12 px-4 hover:bg-slate-100 apply-transition cursor-pointer text-slate-600"
        onClick={handleClick}
      >
        {
          isSub && (
            <div className="h-full w-0.5 bg-slate-200 mx-3"/>
          )
        }
        {icon}
        <p className="flex-1 truncate">{name}</p>
        {
          !isEmpty(subNav) && (
            <motion.div
              initial={false}
              animate={{transform: open ? "rotate(90deg)" : "rotate(0deg)"}}
              transition={{duration: 0.3}}
            >
              <IconChevronRight size={20}/>
            </motion.div>
          )
        }
      </Link>
      <AnimatePresence>
        {
          open && (
            <motion.div
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: {opacity: 1, height: "auto"},
                collapsed: {opacity: 0, height: 0}
              }}
              transition={{duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98]}}
              className="overflow-hidden pl-4"
            >
              {
                subNav?.map((sub, index) => (
                  <NavItem key={`sub-${index}`} isSub {...sub} />
                ))
              }
            </motion.div>
          )
        }
      </AnimatePresence>
    </div>
  );
}

export default NavItem;
