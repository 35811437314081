import apiInstance from "./api";
import {AxiosResponse} from "axios";
import {IAirtimeCashTransactionResponse} from "../types/airtimeCash.types";

class AirtimeCashService {
  getPendingTransactions(page: number = 1, size: number = 10): Promise<IAirtimeCashTransactionResponse> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/airtimetocash/pending", {params: {page, per_page: size}})
        .then((res: AxiosResponse<IAirtimeCashTransactionResponse>) => {
          resolve(res.data)
        })
        .catch(err => reject(err));
    })
  }

  approveTransaction(id: string) {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/airtimetocash/approve?id=${id}`)
        .then((res) => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }

  declineTransaction(id: string, message: string) {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/airtimetocash/deny?id=${id}&message=${message}`)
        .then((res) => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }
}

const airtimeCashService = new AirtimeCashService();
export default airtimeCashService;
