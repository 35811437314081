import React from 'react';
import {Card, CardBody, Stat, StatGroup, StatLabel, StatNumber} from "@chakra-ui/react";
import {useQuery} from "react-query";
import referralService from "../../../services/referral.service";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import utils from "../../../utils/Utils";

interface IProps {
}

function ReferralStats(props: IProps) {
  const {
    data,
    isLoading,
    isLoadingError
  } = useQuery("referralOverview", referralService.getOverview);

  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold mb-2">Overview</h6>

        {
          isLoading ?
            <LoadingContainer/> :
            isLoadingError ?
              <LoadingErrorContainer/> :
              (
                <StatGroup>
                  <Stat>
                    <StatNumber>{data?.signups}</StatNumber>
                    <StatLabel>Signups</StatLabel>
                  </Stat>
                  <Stat>
                    <StatNumber>{data?.kyc}</StatNumber>
                    <StatLabel>KYC</StatLabel>
                  </Stat>
                  <Stat>
                    <StatNumber>{utils.numberWithCommas(data?.qualified_txs)}</StatNumber>
                    <StatLabel>Qualified Txs</StatLabel>
                  </Stat>
                  <Stat>
                    <StatNumber>{data?.available_to_withdraw}</StatNumber>
                    <StatLabel>Available to withdraw</StatLabel>
                  </Stat>
                  <Stat>
                    <StatNumber>{data?.total_withdrawn}</StatNumber>
                    <StatLabel>Total Withdrawn</StatLabel>
                  </Stat>
                  <Stat>
                    <StatNumber>{data?.total_user_rewards}</StatNumber>
                    <StatLabel>Total user rewards</StatLabel>
                  </Stat>
                </StatGroup>
              )
        }
      </CardBody>
    </Card>
  );
}

export default ReferralStats;
