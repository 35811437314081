import React from 'react';
import {
  Alert,
  Box,
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tr,
  useToast
} from "@chakra-ui/react";
import utils from "../../../utils/Utils";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {IBusinessDetails} from "../../../types/business.types";
import {
  IconBuilding,
  IconDiscountCheckFilled,
  IconSettings,
  IconUserCheck,
  IconUserOff
} from "@tabler/icons-react";
import BusinessBankAccounts from "./businessBankAccounts";
import BusinessBvnDetails from "./businessBvnDetails";
import BusinessGovtIdDetails from "./businessGovtIdDetails";
import businessService from "../../../services/business.service";
import {AxiosError} from "axios";

interface IProps {
}

function BusinessData(props: IProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const {id} = useParams<{ id: string }>();
  const {data} = useQuery<IBusinessDetails>(["business", id]);

  const {mutate} = useMutation(businessService.banBusiness, {
    onSuccess: async (message) => {
      await queryClient.invalidateQueries(["business", id]);
      toast({
        status: "success",
        title: "Success",
        description: message,
      });
    },
    onError(error: AxiosError<any>) {
      toast({
        status: "error",
        title: error?.response?.data?.message ?? "Unable to perform action"
      });
    }
  });

  return (
    <Card>
      <CardBody>
        <div className="flex gap-4">
          <div>
            <div
              className="w-16 aspect-square bg-primary-50 text-primary rounded-full grid place-content-center"
            >
              <IconBuilding/>
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            <h5 className="font-semibold">{data?.business_name}</h5>
            <p className="break-words">
              {utils.getText(data?.email)} {!!data?.email_verified_at && <IconDiscountCheckFilled
                size={20} className="text-blue-500 inline"/>}
            </p>
            <p>{utils.getText(data?.phone_number)}</p>

            <Alert status="success" className="mt-4">
              {
                !!data?.account ? (
                  <div>
                    <p>
                      Balance: {" "}
                      <span className="font-semibold">
                      ₦ {utils.numberWithCommas(data?.account?.balance?.toFixed(2))}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>No account details</p>
                )
              }
            </Alert>
          </div>
          <div>
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                icon={<IconSettings/>}
                size="sm"
                variant="ghost"
                aria-label="Actions"
                isRound
              />
              <MenuList>
                {
                  !!data && data.is_restricted ? (
                    <MenuItem
                      color="green.500"
                      onClick={() => mutate({businessId: data.id, ban: false})}
                    >
                      <IconUserCheck className="mr-2" size={20}/>
                      Activate
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color="red.500"
                      onClick={() => mutate({businessId: data?.id!, ban: true})}
                    >
                      <IconUserOff className="mr-2" size={20}/>
                      Deactivate
                    </MenuItem>
                  )
                }
              </MenuList>
            </Menu>
          </div>
        </div>

        <Box p={6} borderWidth="1px" borderRadius="lg" mt={6}>
          <p className="font-semibold mb-4">More Info</p>

          <TableContainer>
            <Table size="sm" variant="simple">
              <Tbody>
                <Tr>
                  <Td>Status</Td>
                  <Td>
                    <Tag colorScheme={!data?.is_disabled ? "green" : "red"}>
                      <TagLabel>{data?.is_disabled ? "Disabled" : "Enabled"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>CAC Verified</Td>
                  <Td>
                    <Tag colorScheme={data?.cac_verified ? "green" : "red"}>
                      <TagLabel>{data?.cac_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>BVN Verified</Td>
                  <Td>
                    <Tag colorScheme={data?.bvn_verified ? "green" : "red"}>
                      <TagLabel>{data?.bvn_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Government Verified</Td>
                  <Td>
                    <Tag colorScheme={data?.government_verified ? "green" : "red"}>
                      <TagLabel>{data?.government_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Address Verified</Td>
                  <Td>
                    <Tag colorScheme={data?.address_verified ? "green" : "red"}>
                      <TagLabel>{data?.address_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>KYC Completed</Td>
                  <Td>
                    <Tag colorScheme={data?.kyc_completed ? "green" : "red"}>
                      <TagLabel>{data?.kyc_completed ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <div className="mt-6 flex flex-wrap gap-4">
          <BusinessBankAccounts/>
          <BusinessBvnDetails/>
          <BusinessGovtIdDetails/>
        </div>
      </CardBody>
    </Card>
  );
}

export default BusinessData;
