import React from 'react';
import {useParams} from "react-router-dom";
import {
  Button,
  Modal, ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay, Table, TableContainer, Tbody, Td, Th, Thead, Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {IBusinessDetails} from "../../../types/business.types";
import utils from "../../../utils/Utils";
import Pagination from "../../../components/Pagination";

interface IProps {
}

function BusinessBeneficiaries(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {data} = useQuery<IBusinessDetails>(["business", id]);
  // const {data: customers} = useQuery(["business", "customers", id], () => businessService.getCustomers(id!));

  return (
    <>
      <div>
        <h6 className="font-semibold">Beneficiaries</h6>
        <p className="small text-slate-500">Business beneficiaries</p>
        <Button onClick={onOpen} mt={4}>See all</Button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Beneficiaries</ModalHeader>
          <ModalBody>
            <TableContainer className="mt-4">
              <Table>
                <Thead>
                  <Th>First Name</Th>
                  <Th>Phone No.</Th>
                  <Th>Email</Th>
                  <Th>Address</Th>
                </Thead>
                <Tbody>
                  {
                    data?.customers?.map(user => (
                      <Tr key={user.id} className="cursor-pointer">
                        <Td>
                          {utils.getText(user.fullname)}
                        </Td>
                        <Td>
                          {utils.getText(user.phone_number)}
                        </Td>
                        <Td>
                          {utils.getText(user.email)}
                        </Td>
                        <Td>
                          {utils.getText(user.address)}
                        </Td>
                      </Tr>
                    ))
                  }
                </Tbody>
              </Table>
            </TableContainer>

            <Pagination
              pageCount={1}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessBeneficiaries;
