import React from 'react';
import {
  Card,
  CardBody, Divider,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from "@chakra-ui/react";
import {IconTriangleFilled, IconTriangleInvertedFilled} from "@tabler/icons-react";

interface IProps {
}

function InflowOutflow(props: IProps) {
  return (
    <Card>
      <CardBody>
        <Tabs position="relative" variant="unstyled" size="sm">
          <TabList>
            <Tab>1 week</Tab>
            <Tab>1 month</Tab>
            <Tab>All time</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <InflowOutflowData/>
            </TabPanel>
            <TabPanel>
              <InflowOutflowData/>
            </TabPanel>
            <TabPanel>
              <InflowOutflowData/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
}

function InflowOutflowData() {
  return (
    <>
      <div>
        <div className="flex items-center gap-2">
          <IconTriangleFilled size={20} className="text-red-500"/>
          <p>Total Outflow</p>
        </div>
        <h4 className="font-semibold">₦ 63,766,937.66</h4>
      </div>
      <Divider className="my-4"/>
      <div>
        <div className="flex items-center gap-2">
          <IconTriangleInvertedFilled size={20} className="text-green-500"/>
          <p>Total Inflow</p>
        </div>
        <h4 className="font-semibold">₦ 63,766,937.66</h4>
      </div>
    </>
  )
}

export default InflowOutflow;
