import React from 'react';
import {IconArrowNarrowRight} from "@tabler/icons-react";
import Header from "../../../../components/Layout/Header";
import PendingTransTable from "./pendingTransTable";
import TransactionVolume from "../BankTransfer/transactionVolume";
import TransactionAnalysis from "../BankTransfer/transactionAnalysis";
import Settings from "../BankTransfer/settings";

interface IProps {
}

function CC_Personal_AirtimeToCashPage(props: IProps) {
  return (
    <div>
      <Header>
        CC <IconArrowNarrowRight className="mx-2"/> Personal <IconArrowNarrowRight
        className="mx-2"/> Airtime to Cash
      </Header>

      <div className="px-4 grid gap-4">
        <div className="grid grid-cols-3 gap-4">
          <TransactionVolume type="AirTimeToCash"/>

          <div className="grid gap-4">
            <TransactionAnalysis/>
          </div>

          <Settings/>
        </div>

        <PendingTransTable/>
      </div>
    </div>
  );
}

export default CC_Personal_AirtimeToCashPage;
