import {
  Card,
  CardBody,
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "react-query";
import Header from "../../../components/Layout/Header";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import crmService from "../../../services/crm.service";

interface IProps {}
function CrmTemplatesPage(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { isLoading, data, isError } = useQuery(
    ["templates", page, size],
    () => crmService.getTemplates(page, size),
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  return (
    <div>
      <Header title="CRM Message Templates" />

      <div className="grid gap-4 px-4">
        <Card>
          <CardBody>
            <h6 className="font-semibold">CRM Message Templates</h6>

            <TableContainer mt={6}>
              <Table>
                <Thead>
                  <Th>Message</Th>
                  <Th>In-App</Th>
                  <Th>Push</Th>
                  <Th>SMS</Th>
                  <Th>Email</Th>
                  <Th>Actions</Th>
                </Thead>
                <Tbody>
                  {isLoading ? (
                    <Tr>
                      <Td colSpan={3}>
                        <p className="text-center">Loading...</p>
                      </Td>
                    </Tr>
                  ) : isError ? (
                    <LoadingErrorContainer className="h-auto" />
                  ) : (
                    data?.map((template) => (
                      <Tr key={template.id} className="with-hover">
                        <Td className="max-w-xs overflow-hidden">
                          <p className="truncate">{template.message}</p>
                        </Td>
                        <Td>
                          <Tag
                            colorScheme={
                              template.in_app ? "green" : "blackAlpha"
                            }
                          >
                            <TagLabel>
                              {template.in_app ? "True" : "False"}
                            </TagLabel>
                          </Tag>
                        </Td>
                        <Td>
                          <Tag
                            colorScheme={template.push ? "green" : "blackAlpha"}
                          >
                            <TagLabel>
                              {template.push ? "True" : "False"}
                            </TagLabel>
                          </Tag>
                        </Td>
                        <Td>
                          <Tag
                            colorScheme={template.sms ? "green" : "blackAlpha"}
                          >
                            <TagLabel>
                              {template.sms ? "True" : "False"}
                            </TagLabel>
                          </Tag>
                        </Td>
                        <Td>
                          <Tag
                            colorScheme={
                              template.email ? "green" : "blackAlpha"
                            }
                          >
                            <TagLabel>
                              {template.email ? "True" : "False"}
                            </TagLabel>
                          </Tag>
                        </Td>
                        <Td>more</Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default CrmTemplatesPage;
