import React from 'react';

interface IProps {
}

function AppLoading(props: IProps) {
  return (
    <div></div>
  );
}

export default AppLoading;
