import React, {useMemo} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {IBusinessDetails} from "../../../types/business.types";

interface IProps {
  userId?: string;
}

function BusinessGovtIdDetails({userId}: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {id} = useParams<{ id: string }>();
  const {data} = useQuery<IBusinessDetails>(["business", id]);

  const details = useMemo(() => {
    if (!!data && !!data.government_verification) {
      return Object.keys(data.government_verification).map(k => ({
        key: k,
        value: data.government_verification[k]
      }));
    }
    return [];
  }, [data]);

  return (
    <>
      <Button onClick={onOpen}>Govt. ID Details</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Govt. ID Details</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              <TableContainer>
                <Table>
                  <Thead>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Thead>
                  <Tbody>
                    {
                      details.map((k, i) => (
                        <Tr key={i}>
                          <Td>{k.key}</Td>
                          <Td>{k.value}</Td>
                        </Tr>
                      ))
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessGovtIdDetails;
