import React from 'react';
import {Bar} from "react-chartjs-2";
import {ChartData, ChartOptions} from "chart.js";

interface IProps {
}

function RevenueChart(props: IProps) {

  const data: ChartData<"bar", (number | [number, number] | null)[], unknown> = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Revenue",
        data: [45, 44, 23, 64, 32, 67, 32, 55, 15, 48, 29, 42],
      }
    ]
  }

  const options: ChartOptions<"bar"> = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#FFF",
        }
      },
      y: {
        grid: {
          color: "rgba(247,232,233,0.2)",
        },
        ticks: {
          color: "#F7E8E9",
        }
      }
    },
    datasets: {
      bar: {
        backgroundColor: "#F7E8E9",
        hoverBackgroundColor: "#5E5E5E",
        barPercentage: 0.6
      }
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
  }

  return (
    <div>
      <div className="bg-primary text-white p-6">
        <div className="h-[250px]">
          <Bar data={data} options={options}/>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-6 p-6">
        <div>
          <p>Revenue</p>
          <h6 className="font-semibold">53,145,000.00 M</h6>
        </div>
        <div>
          <p>Charges In</p>
          <h6 className="font-semibold">53,145,000.00 M</h6>
        </div>
        <div>
          <p>Charges Out</p>
          <h6 className="font-semibold">53,145,000.00 M</h6>
        </div>
      </div>
    </div>
  );
}

export default RevenueChart;
