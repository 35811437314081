import React, {useMemo} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import userService from "../../../services/user.service";

interface IProps {
  userId?: string;
}

function BvnDetails({userId}: IProps) {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {data} = useQuery(["user", "bvnDetails", userId], () => userService.getBvnDetails(userId ?? ""));

  const details = useMemo(() => {
    if (data) {
      return Object.keys(data).map(k => ({key: k, value: data[k]}));
    }
    return [];
  }, [data]);

  return (
    <>
      <Button onClick={onOpen}>BVN Details</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>BVN Details</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              {
                !!data && (
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                      <p className="font-semibold">BVN Image</p>

                      <img src={data.bvn_image} alt="BVN Image" className="w-full h-auto mt-4"/>
                    </div>
                    <div>
                      <p className="font-semibold">Liveliness Image</p>

                      <img src={data.liveliness_image} alt="Liveliness Image"
                           className="w-full h-auto mt-4"/>
                    </div>
                  </div>
                )
              }
              <TableContainer>
                <Table>
                  <Thead>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Thead>
                  <Tbody>
                    {
                      details.map((k, i) => (
                        <Tr key={i}>
                          <Td>{k.key}</Td>
                          <Td>{k.value}</Td>
                        </Tr>
                      ))
                    }
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BvnDetails;
