import React from 'react';
import {Line} from 'react-chartjs-2';
import {Card, CardBody} from "@chakra-ui/react";

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const data = {
  labels,
  datasets: [
    {
      label: 'Transfers',
      data: [487, 232, 435, 456, 233, 534, 155, 89, 238, 789, 826, 578],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
}

interface IProps {
}

function UsersTransactionsChart(props: IProps) {
  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold mb-2">Bank transfers</h6>

        <Line options={{
          responsive: true,
          elements: {
            point: {
              pointStyle: false,
            }
          },
          scales: {
            x: {
              grid: {
                display: true,
              }
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          }
        }} data={data}/>
      </CardBody>
    </Card>
  );
}

export default UsersTransactionsChart;
