import React, {useState} from 'react';
import {
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {useQuery} from "react-query";
import airtimeCashService from "../../../../services/airtimeCash.service";
import Pagination from "../../../../components/Pagination";
import utils from "../../../../utils/Utils";
import {IconDotsVertical} from "@tabler/icons-react";
import ApproveTrans from "./ApproveTrans";
import DeclineTrans from "./DeclineTrans";

interface IProps {
}

function PendingTransTable(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const {
    isLoading,
    data
  } = useQuery(["pendingAirtimeToCash", page, size], () => airtimeCashService.getPendingTransactions(page, size), {keepPreviousData: true});


  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold flex-1">Pending Transactions</h6>

        <TableContainer mt={6}>
          <Table>
            <Thead>
              <Tr>
                <Th>User ID</Th>
                <Th>Phone number</Th>
                <Th>Network</Th>
                <Th>Amount</Th>
                <Th>Fee</Th>
                <Th>Airtime value</Th>
                <Th>Status</Th>
                <Th>Date created</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>

              {
                isLoading && (
                  <Tr>
                    <Td colSpan={9}>
                      <div className="text-center flex items-center justify-center gap-4">
                        <Spinner size="sm"/> Loading...
                      </div>
                    </Td>
                  </Tr>
                )
              }

              {
                !!data && data.data.map((transaction) => (
                  <Tr key={transaction.id}>
                    <Td>{transaction.user_id}</Td>
                    <Td>{transaction.phone_number}</Td>
                    <Td>{transaction.network.toUpperCase()}</Td>
                    <Td>{transaction.amount}</Td>
                    <Td>{transaction.fee}</Td>
                    <Td>{transaction.airtime_value}</Td>
                    <Td>
                      <Tag>
                        <TagLabel>
                          {transaction.status}
                        </TagLabel>
                      </Tag>
                    </Td>
                    <Td>{utils.formatDate(new Date(transaction.created_at))}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<IconDotsVertical size={20}/>}
                          size="sm"
                          aria-label="Actions"
                          isRound
                        />
                        <MenuList>
                          <ApproveTrans transaction={transaction} page={page}/>
                          <DeclineTrans transaction={transaction} page={page}/>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              }

            </Tbody>
          </Table>
        </TableContainer>

        {
          !!data &&
            <Pagination
                pageCount={Math.ceil(data.total / data.per_page)}
                onPageChange={e => !!setPage ? setPage(e.selected + 1) : null}
                forcePage={(data.current_page ?? 1) - 1}
                size={size}
                onSizeChange={setSize}
                pagination={{
                  total: data.total,
                  from: data.from,
                  current_page: data.current_page,
                  per_page: data.per_page,
                  to: data.to,
                  last_page: data.last_page
                }}

            />
        }

      </CardBody>
    </Card>
  );
}

export default PendingTransTable;
