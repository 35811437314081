import React from 'react';
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {IconPencil} from "@tabler/icons-react";
import {useQuery, useQueryClient} from "react-query";
import tierService from "../../../services/tier.service";
import utils from "../../../utils/Utils";
import {ITier} from "../../../types/tiers.types";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import TextInput from "../../../components/TextInput";
import userService from "../../../services/user.service";

interface IProps {
}

function Tiers(props: IProps) {
  const {
    data: tiers,
    isLoading: tiersLoading
  } = useQuery("tiers", tierService.getTiers, {refetchOnWindowFocus: false});

  const {
    data: activeUsers,
    isLoading: activeUsersLoading
  } = useQuery(["users", "active"], () => userService.getAllUsersCount("active"), {
    retry: 1,
    refetchOnWindowFocus: false
  })
  const {
    data: inactiveUsers,
    isLoading: inactiveUsersLoading
  } = useQuery(["users", "inactive"], () => userService.getAllUsersCount("inactive"), {
    retry: 1,
    refetchOnWindowFocus: false
  })

  return (
    <div className="grid gap-4">
      <Card>
        <CardBody>
          <h6 className="font-semibold mb-2">User count</h6>
          <h4>
            {
              activeUsersLoading || inactiveUsersLoading
                ? <Spinner size="sm"/>
                : utils.numberWithCommas((activeUsers ?? 0) + (inactiveUsers ?? 0))
            }
          </h4>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="flex items-center gap-4 mb-4">
            <h6 className="font-semibold flex-1">Personal Tiers</h6>
          </div>

          <TableContainer>
            <Table size="sm" variant="simple">
              <Thead>
                <Th>Tier</Th>
                <Th>Daily limit</Th>
                <Th>Weekly limit</Th>
                <Th>Monthly limit</Th>
                <Th>Action</Th>
              </Thead>
              <Tbody>
                {
                  tiersLoading ? (
                      <Tr>
                        <Td colSpan={5}>
                          <div className="flex items-center flex-col text-center py-5">
                            <Spinner size="sm"/>
                            <p className="small text-slate-400">Loading</p>
                          </div>
                        </Td>
                      </Tr>
                    ) :
                    tiers?.map(tier => (
                      <TierRow tier={tier} key={tier.id}/>
                    ))
                }
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
    </div>
  );
}

function TierRow({tier}: { tier: ITier }) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const onSubmit = (values: ITier, helpers: FormikHelpers<ITier>) => {
    tierService.updateTier(values)
      .then(() => {
        toast({status: "success", title: "Updated successfully"});
        queryClient.invalidateQueries("tiers");
        helpers.setSubmitting(false);
        onClose();
      })
      .catch(err => {
        helpers.setSubmitting(false);
        toast({
          status: "error",
          title: err.response?.data?.data?.error ?? "Unable to update tier"
        })
      })
  }

  return (
    <>
      <Tr>
        <Td>{tier.name}</Td>
        <Td>₦ {utils.numberWithCommas(Number(tier.daily_amount))}</Td>
        <Td>₦ {utils.numberWithCommas(Number(tier.weekly_amount))}</Td>
        <Td>₦ {utils.numberWithCommas(Number(tier.monthly_amount))}</Td>
        <Td>
          <IconButton aria-label="Edit" icon={<IconPencil size={20}/>} size="sm" onClick={onOpen}/>
        </Td>
      </Tr>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton/>
            Edit tier
          </ModalHeader>
          <ModalBody>
            <Formik initialValues={tier} onSubmit={onSubmit} enableReinitialize>
              {({isSubmitting, isValid}) => (
                <Form className="grid gap-4 pb-5">
                  <Field name="name">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Name"
                        placeholder="Name"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="daily_amount">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Daily limit"
                        placeholder="Daily limit"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="weekly_amount">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Weekly limit"
                        placeholder="Weekly limit"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="monthly_amount">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Monthly limit"
                        placeholder="Monthly limit"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <div>
                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                      colorScheme="brand"
                      size="lg"
                      type="submit"
                      className="w-full"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Tiers;
