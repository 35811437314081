import React, {useState} from 'react';
import {IUserDetails} from "../../../types/auth.types";
import {Button, useToast} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import {IconFileDownload} from "@tabler/icons-react";

interface IProps {
  fetchAllForExport: () => Promise<IUserDetails[]>;
  className?: string;
}

function ExportUsers({fetchAllForExport, className}: IProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleExport = () => {
    if (!loading) {
      setLoading(true)
      fetchAllForExport()
        .then((res) => {
          const transformed = res.map(t => ({
            id: t.id,
            first_name: t.first_name,
            middle_name: t.middle_name,
            last_name: t.last_name,
            email: t.email,
            phone_number: t.phone_number,
            account_type: t.account_type,
            tier: t.account_tier,
            status: t.is_dissabled,
            bank: t.accountdetail?.bank_name ?? "-",
            account_number: t.accountdetail?.account_number ?? "-",
            balance: t.accountdetail?.balance ?? 0,
            referral_code: t.referal_code,
            referred_by: t.referal_by,
            date_joined: t.created_at.toString(),
          }));
          const worksheet = XLSX.utils.json_to_sheet(transformed);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, `users-${Date.now()}.xlsx`);
          setLoading(false);
          toast({status: "success", title: "Users exported successfully"});
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast({status: "error", title: "Unable to export transactions"});
        })
    }
  }

  return (
    <Button isLoading={loading} onClick={handleExport} className={className}>
      <IconFileDownload size={20} className="mr-1"/> Export
    </Button>
  );
}

export default ExportUsers;
