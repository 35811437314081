import {ITier} from "../types/tiers.types";
import apiInstance from "./api";
import {AxiosResponse} from "axios";

class TierService {
  getTiers(): Promise<ITier[]> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/tiers")
        .then((res: AxiosResponse<ITier[]>) => {
          resolve(res.data)
        })
        .catch(err => reject(err));
    })
  }

  updateTier(tier: ITier) {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/transactions/tiers/${tier.id}/update`, tier)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }
}

const tierService = new TierService();
export default tierService;
