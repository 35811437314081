import React from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {AxiosError} from "axios";
import businessService from "../../../services/business.service";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {IUserDetails} from "../../../types/auth.types";
import utils from "../../../utils/Utils";
import TdLink from "../../../components/TdLink";

interface IProps {
}

function BusinessManagers(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isLoading,
    data,
    isLoadingError,
    error
  } = useQuery<IUserDetails[], AxiosError<any>>(
    ["business", id, "managers"],
    () => businessService.getBusinessManagers(id!),
    {refetchOnWindowFocus: false, refetchInterval: false, retry: 1}
  );

  return (
    <>
      <div>
        <h6 className="font-semibold">Business Managers</h6>
        <p className="small text-slate-500">Users that manage this business</p>
        <Button onClick={onOpen} mt={4}>See all</Button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Business Managers</ModalHeader>
          <ModalBody pb={10}>
            {
              isLoading ? (
                <LoadingContainer/>
              ) : isLoadingError ? (
                <LoadingErrorContainer errorMessage={error?.response?.data?.data?.error}/>
              ) : (
                <TableContainer className="mt-4">
                  <Table>
                    <Thead>
                      <Th>First Name</Th>
                      <Th>Last Name</Th>
                      <Th>Email</Th>
                    </Thead>
                    <Tbody>
                      {
                        data?.map(user => (
                          <Tr key={user.id} className="with-hover">
                            <TdLink to={`/users/${user.id}`}>
                              {utils.getText(user.first_name)}
                            </TdLink>
                            <TdLink to={`/users/${user.id}`}>
                              {utils.getText(user.last_name)}
                            </TdLink>
                            <TdLink to={`/users/${user.id}`}>
                              {utils.getText(user.email)}
                            </TdLink>
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                </TableContainer>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BusinessManagers;
