import React from 'react';
import {Card, CardBody, Divider, IconButton, StatUpArrow} from "@chakra-ui/react";
import {Doughnut} from "react-chartjs-2";
import {ChartData, ChartOptions} from "chart.js";
import {IconDotsVertical} from "@tabler/icons-react";

interface IProps {
}

function UsersChart(props: IProps) {
  const data: ChartData<"doughnut", number[], unknown> = {
    labels: ["Active", "Inactive"],
    datasets: [
      {
        data: [8937, 3256],
        backgroundColor: [
          "#262626",
          "#B21C26",
        ]
      }
    ],
  }

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      legend: {
        display: false,
      }
    }
  }

  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="px-6 py-4 flex items-center gap-4">
          <div className="flex-1">
            <h6 className="font-semibold">Total Users</h6>
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              aria-label="more"
              icon={<IconDotsVertical/>}
              variant="ghost"
              size="sm"
            />
          </div>
        </div>

        <Divider/>

        <div className="max-h-[250px] flex flex-col items-center p-6">
          <Doughnut data={data} options={options}/>
        </div>

        <Divider/>

        <div className="px-6 flex items-center gap-4">
          <div className="py-4 text-center flex-1">
            <h6 className="text-primary font-semibold">8,937 <StatUpArrow/></h6>
            <p className="small">Active</p>
          </div>
          <div className="py-4 text-center flex-1">
            <h6 className="text-[#262626] font-semibold">3,256 <StatUpArrow/></h6>
            <p className="small">Inactive</p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default UsersChart;
