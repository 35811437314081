import React, {useCallback, useEffect, useState} from 'react';
import TransactionsTable from "../../../components/TransactionsTable";
import {useQuery} from "react-query";
import transactionService from "../../../services/transaction.service";
import LoadingContainer from "../../../components/LoadingContainer";
import {useParams} from "react-router-dom";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import {Page} from "../../../types/common";
import {ITransaction} from "../../../types/transaction.types";
import {AxiosError} from "axios";

interface IProps {
}

function BusinessTransactions(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filterApplied, setFilterApplied] = useState({});

  useEffect(() => {
    setPage(1);
  }, [filterApplied]);

  const {data, isLoading, isLoadingError, error, refetch, isRefetching} = useQuery<Page<ITransaction>, AxiosError<any>>(
    ["transactions", "business", page, size, JSON.stringify({
      ...filterApplied,
      business_account_id: id
    })],
    () => transactionService.getAllTransactions(page, size, {
      ...filterApplied,
      business_account_id: id
    }, "BUSINESS"),
    {keepPreviousData: true, refetchOnWindowFocus: false, retry: 0}
  );

  const fetchAllForExport = useCallback(async () => {
    const transactions = await transactionService.getAllTransactions(0, data?.pagination.total, {
      ...filterApplied,
      business_account_id: id
    }, "BUSINESS");
    return transactions.data;
  }, [data?.pagination.total, filterApplied, id]);

  return (
    isLoading ? (
      <LoadingContainer/>
    ) : !data || isLoadingError ? (
      <LoadingErrorContainer
        errorMessage={error?.response?.data?.data?.error ?? "Unable to fetch transactions"}
        refetch={refetch}
        isRefetching={isRefetching}
        className="h-fit py-10"
      />
    ) : (
      <TransactionsTable
        data={data.data}
        pagination={data.pagination}
        setPage={setPage}
        applyFilter={setFilterApplied}
        appliedFilter={filterApplied}
        size={size}
        setSize={setSize}
        fetchAllForExport={fetchAllForExport}
      />
    )
  );
}

export default BusinessTransactions;
