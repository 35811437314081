import React, {useState} from 'react';
import {Button, useToast} from "@chakra-ui/react";
import {IconFileDownload} from "@tabler/icons-react";
import {ITransaction, ITransactionExportData} from "../../types/transaction.types";
import * as XLSX from "xlsx";

interface IProps {
  fetchAllForExport: () => Promise<ITransaction[]>;
}

function ExportTransactions({fetchAllForExport}: IProps) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleExport = () => {
    if (!loading) {
      setLoading(true)
      fetchAllForExport()
        .then((res) => {
          const transformed: ITransactionExportData[] = res.map(t => ({
            id: t.id,
            reference: t.reference,
            user: `${t?.user?.first_name} ${t?.user?.middle_name} ${t?.user?.last_name}`,
            to_from: t.to_from,
            amount: t.transaction_type === "credit" ? t.inflow : t.outflow,
            type: t.transaction_type,
            category: t.category,
            status: t.transaction_status,
            date: t.created_at?.toString(),
          }));
          const worksheet = XLSX.utils.json_to_sheet(transformed);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook, `transactions-${Date.now()}.xlsx`);
          setLoading(false);
          toast({status: "success", title: "Transactions exported successfully"});
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast({status: "error", title: "Unable to export transactions"});
        })
    }
  }

  return (
    <Button isLoading={loading} onClick={handleExport}>
      <IconFileDownload size={20} className="mr-1"/> Export
    </Button>
  );
}

export default ExportTransactions;
