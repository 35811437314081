import React from 'react';
import Header from "../../../components/Layout/Header";
import UserData from "./userData";
import InflowOutflow from "./inflowOutflow";
import TransactionsBreakdown from "./transactionsBreakdown";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import userService from "../../../services/user.service";
import LoadingContainer from "../../../components/LoadingContainer";
import utils from "../../../utils/Utils";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import {IUserProfile} from "../../../types/auth.types";
import {AxiosError} from "axios";
import UserReferralStats from "./userReferralStats";
import UserTransactions from "./transactions";
import Tier from "./tier";

interface IProps {
}

function IndividualUserPage(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {
    isLoading,
    data,
    isLoadingError,
    error
  } = useQuery<IUserProfile | undefined, AxiosError<any>>(
    ["user", id],
    () => userService.getUserDetails(id),
    {refetchOnWindowFocus: false, refetchInterval: false, retry: 2}
  );

  return (
    <div>
      <Header
        title={`User: ${isLoading ? "..." : utils.getText(`${data?.profile.first_name} ${data?.profile.last_name}`)}`}
      />

      {
        isLoading ? (
          <LoadingContainer/>
        ) : isLoadingError ? (
          <LoadingErrorContainer errorMessage={error?.response?.data?.data?.error}/>
        ) : (
          <div className="grid grid-cols-1 gap-4 px-4">
            <div className="grid grid-cols-3 gap-4">
              <UserData/>

              <div className="grid gap-4">
                <Tier/>
                <UserReferralStats/>
              </div>

              <div>
                <div className="grid gap-4">
                  <InflowOutflow/>
                  <TransactionsBreakdown/>
                </div>
              </div>

            </div>

            <UserTransactions/>

          </div>
        )
      }
    </div>
  );
}

export default IndividualUserPage;
