import React from 'react';
import {Card, CardBody, Spinner, Stat, StatLabel, StatNumber} from "@chakra-ui/react";
import {useQuery} from "react-query";
import transactionService from "../../../../services/transaction.service";
import utils from "../../../../utils/Utils";

interface IProps {
  type: string
}

function TransactionVolume({type}: IProps) {
  const {
    data,
    isLoading
  } = useQuery(["transactions", type], () => transactionService.getTransactionVolumeByType(type), {
    refetchOnWindowFocus: false,
    retry: 1
  });

  const {
    data: count,
    isLoading: countLoading
  } = useQuery(["transactions", "count", type], () => transactionService.getTransactionCountByType(type), {
    refetchOnWindowFocus: false,
    retry: 1
  });

  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold mb-2">Transactions Volume</h6>
        <h3 className="font-semibold">
          {isLoading ? <Spinner/> : `₦ ${utils.numberWithCommas(data)}`}
        </h3>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <Stat>
            <StatLabel>Processed</StatLabel>
            <StatNumber>
              {countLoading ? <Spinner/> : `₦ ${utils.numberWithCommas(count)}`}
            </StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Revenue</StatLabel>
            <StatNumber>₦ 475,846</StatNumber>
          </Stat>
        </div>
      </CardBody>
    </Card>
  );
}

export default TransactionVolume;
