import React from 'react';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tr,
  useToast
} from "@chakra-ui/react";
import {IconSettings, IconSnowflake, IconUserCheck, IconUserOff} from "@tabler/icons-react";
import {useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {IUserProfile} from "../../../types/auth.types";
import utils from "../../../utils/Utils";
import userService from "../../../services/user.service";
import BvnDetails from "./bvnDetails";
import GovtIdDetails from "./govtIdDetails";
import UserBankAccounts from "./userBankAccounts";

interface IProps {
}

function UserData(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const {data} = useQuery<IUserProfile>(["user", id]);
  const toast = useToast();

  const {mutate} = useMutation(userService.banUser, {
    onSuccess(newUserData) {
      queryClient.setQueryData(["user", id], {...data, profile: newUserData});
    }
  });

  const {mutate: lock} = useMutation(userService.lockUserTransactions, {
    onSuccess(message, variables) {
      toast({status: "success", title: message});
      queryClient.setQueryData(["user", id], {
        ...data,
        profile: {...data?.profile, transaction_lock: variables.lock ? 1 : 0}
      });
      queryClient.invalidateQueries(["user", id]);
    }
  });

  return (
    <Card>
      <CardBody>
        <div className="flex gap-4">
          <div>
            <Avatar/>
          </div>
          <div className="flex-1 overflow-hidden">
            <h5 className="font-semibold">{data?.profile.first_name} {data?.profile.last_name}</h5>
            <p className="break-words">{utils.getText(data?.profile.email)}</p>
            <p>{utils.getText(data?.profile.phone_number)}</p>
            <Alert status="success" className="mt-4">
              {
                !!data?.profile.accountdetail ? (
                  <div>
                    <p>
                      Balance: {" "}
                      <span className="font-semibold">
                      ₦ {utils.numberWithCommas(data?.profile.accountdetail?.balance?.toFixed(2))}
                      </span>
                    </p>
                    <p>
                      Daily limit: {" "}
                      <span className="font-semibold">
                      ₦ {utils.numberWithCommas(Number(data.profile.account_daily_limit).toFixed(2))}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>No account details</p>
                )
              }
            </Alert>
          </div>
          <div>
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                icon={<IconSettings/>}
                size="sm"
                variant="ghost"
                aria-label="Actions"
                isRound
              />
              <MenuList>
                {
                  !!data && data.profile.is_restricted ? (
                    <MenuItem
                      color="green.500"
                      onClick={() => mutate({userId: data.profile.id, ban: false})}
                    >
                      <IconUserCheck className="mr-2" size={20}/>
                      Activate
                    </MenuItem>
                  ) : (
                    <MenuItem
                      color="red.500"
                      onClick={() => mutate({userId: data?.profile.id!, ban: true})}
                    >
                      <IconUserOff className="mr-2" size={20}/>
                      Deactivate
                    </MenuItem>
                  )
                }
                <MenuItem
                  color="darkblue"
                  onClick={() => lock({
                    userId: data?.profile.id!,
                    lock: data?.profile.transaction_lock !== 1
                  })}
                >
                  <IconSnowflake className="mr-2"/>
                  {
                    data?.profile.transaction_lock === 1 ? "Unfreeze transactions" : "Freeze Transactions"
                  }
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>

        <Box p={6} borderWidth="1px" borderRadius="lg" mt={6}>
          <p className="font-semibold mb-4">User Data</p>

          <TableContainer>
            <Table size="sm" variant="simple">
              <Tbody>
                <Tr>
                  <Td>Name</Td>
                  <Td>{utils.getText(`${data?.profile.first_name} ${data?.profile.last_name}`)}</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{utils.getText(data?.profile.email)}</Td>
                </Tr>
                <Tr>
                  <Td>BVN Verified</Td>
                  <Td>
                    <Tag colorScheme={data?.profile.bvn_verified ? "green" : "red"}>
                      <TagLabel>{data?.profile.bvn_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>DOB</Td>
                  <Td>
                    {
                      !!data?.profile.date_of_birth ?
                        utils.formatDate(new Date(data.profile.date_of_birth)) :
                        "-"
                    }
                  </Td>
                </Tr>
                <Tr>
                  <Td>Phone</Td>
                  <Td>{utils.getText(data?.profile.phone_number)}</Td>
                </Tr>
                <Tr>
                  <Td>Acc. No.</Td>
                  <Td>{utils.getText(data?.profile.accountdetail?.account_number)}</Td>
                </Tr>
                <Tr>
                  <Td>Bank</Td>
                  <Td>{utils.getText(data?.profile.accountdetail?.bank_name)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <div className="flex flex-wrap gap-2 mt-4">
            <BvnDetails userId={id}/>
            <GovtIdDetails userId={id}/>
            <UserBankAccounts/>
          </div>
        </Box>
      </CardBody>
    </Card>
  );
}

export default UserData;
