import { store } from "../redux/store";
import isEmpty from "is-empty";
import apiInstance, {setAuthToken} from "./api";
import {authActions} from "../redux/slices/authSlice";
import {IAdminDetails, ILogin, IRegister} from "../types/auth.types";
import {AxiosError, AxiosResponse} from "axios";
import {IChangePassword} from "../types/admin.types";

class AuthService {
  register(data: IRegister): Promise<boolean> {
    return new Promise((resolve, reject) => {
      apiInstance
        .post("/admin/register", data)
        .then((res: AxiosResponse) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  login(data: ILogin): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      apiInstance
        .post("/admin/login", data)
        .then((res) => {
          const token = `Bearer ${res.data.token}`;
          setAuthToken(token);
          store.dispatch(authActions.login(token));
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  updatePassword(data: IChangePassword) {
    return new Promise((resolve, reject) => {
      apiInstance
        .post("/admin/create-new-password-from-profile", {
          current_password: data.oldPassword,
          new_password: data.newPassword,
          confirm_new_password: data.newPassword2,
        })
        .then((res) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  getUserDetails(): Promise<IAdminDetails> {
    const authInstance = authService;
    return new Promise<IAdminDetails>((resolve, reject) => {
      apiInstance
        .get("/admin/dashboard")
        .then((res: AxiosResponse<{ user: IAdminDetails }>) => {
          resolve(res.data.user);
        })
        .catch((err: AxiosError<any>) => {
          if(err.response?.status === 401){
            authInstance.logout();
          } else {
            reject(err);
          }
        });
    })
  }

  async reAuthenticate() {
    try {
      const token = store.getState().auth?.token;
      if (!isEmpty(token)) {
        setAuthToken(token);
      } else {
        this.logout();
      }
    } catch (e: any) {
      this.logout();
    }
  }

  logout() {
    setAuthToken();
    store.dispatch(authActions.logout());
  }
}

const authService = new AuthService();
export default authService;
