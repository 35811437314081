import React from 'react';
import Header from "../../../../components/Layout/Header";
import TransactionVolume from "../BankTransfer/transactionVolume";
import TransactionAnalysis from "../BankTransfer/transactionAnalysis";
import Settings from "../BankTransfer/settings";
import TransactionsTable from "../../../../components/TransactionsTable";
import {IconArrowNarrowRight} from "@tabler/icons-react";

interface IProps {
}

function CC_Personal_Fago2FagoPage(props: IProps) {
  return (
    <div>
      <Header>
        CC <IconArrowNarrowRight className="mx-2"/> Personal <IconArrowNarrowRight
        className="mx-2"/> Fago 2 Fago
      </Header>

      <div className="grid gap-4 px-4">
        <div className="grid grid-cols-3 gap-4">
          <TransactionVolume type="P2P Transfer"/>

          <div className="grid gap-4">
            <TransactionAnalysis/>
          </div>

          <Settings/>
        </div>

        <TransactionsTable data={[]}/>
      </div>
    </div>
  );
}

export default CC_Personal_Fago2FagoPage;
