import React from 'react';
import {Button, Card, CardBody, Tag, TagLabel} from "@chakra-ui/react";
import {IconPencil} from "@tabler/icons-react";

interface IProps {
}

function Settings(props: IProps) {
  return (
    <div>
      <h6 className="font-semibold mb-2 flex-1">Settings</h6>

      <div className="grid gap-4">
        <Card>
          <CardBody>
            <div className="flex items-center gap-4">
              <p className="font-semibold flex-1">Feature Status</p>
              <Tag colorScheme="green">
                <TagLabel>ACTIVE</TagLabel>
              </Tag>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="flex items-center gap-4">
              <p className="font-semibold flex-1">Transaction Charge</p>
              <Button size="sm">Edit <IconPencil size={16} className="ml-2"/></Button>
            </div>

            <h4>10%</h4>
          </CardBody>
        </Card>

        <Card size="sm">
          <CardBody>
            <div className="flex items-center gap-4 mb-2">
              <p className="font-semibold flex-1">Approvals</p>
              <Button size="sm">Edit <IconPencil size={16} className="ml-2"/></Button>
            </div>

            <div className="flex items-center gap-6">
              <div>
                <h6>₦ 0 - 50,000</h6>
                <p>Automatic</p>
              </div>

              <div>
                <h6>₦ 50,000 - above</h6>
                <p>Manual</p>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Settings;
