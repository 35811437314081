import React, {useMemo} from 'react';
import {Card, CardBody, Spinner, Stat, StatLabel, StatNumber} from "@chakra-ui/react";
import {
  IconCurrencyNaira,
  IconTriangleFilled,
  IconTriangleInvertedFilled
} from "@tabler/icons-react";
import {Pie} from 'react-chartjs-2';
import {useQuery} from "react-query";
import transactionService from "../../../services/transaction.service";
import utils from "../../../utils/Utils";
import {ITransactionOverview} from "../../../types/transaction.types";

const data = {
  labels: ["Electricity", "Transfer", "Internet", "Others"],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 0,
    },
  ],
};

interface IProps {
}

function PersonalBalance(props: IProps) {
  const {
    data: total,
    isLoading: totalLoading
  } = useQuery(["transactions", "total"], transactionService.getTotalTransactions, {
    refetchOnWindowFocus: false,
    retry: 0
  });

  const {
    data: overview,
    isLoading,
    isLoadingError,
    error
  } = useQuery(["transactions", "overview"], transactionService.getOverview, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  const transVolume = useMemo(() => {
    if (overview) {
      const newOverview = {...overview};
      // @ts-ignore
      delete newOverview.all;

      return {
        labels: Object.keys(newOverview).map(o => o.toUpperCase()),
        datasets: [
          {
            label: "Transaction Volume",
            // @ts-ignore
            data: Object.keys(newOverview).map((o: keyof ITransactionOverview) => {
              const item = newOverview[o].total.replace(/(,|\(|\))+/g, "").split(" ");
              return Number(item[1]);
            }),
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0,
          },
        ],
      }
    }
    return {
      labels: [],
      datasets: []
    }
  }, [overview]);

  const revVolume = useMemo(() => {
    if (overview) {
      const newOverview = {...overview};
      // @ts-ignore
      delete newOverview.all;

      return {
        labels: Object.keys(newOverview).map(o => o.toUpperCase()),
        datasets: [
          {
            label: "Transaction Volume",
            // @ts-ignore
            data: Object.keys(newOverview).map((o: keyof ITransactionOverview) => {
              const item = newOverview[o].total.replace(/(,|\(|\))+/g, "").split(" ");
              return Number(item[0]);
            }),
            backgroundColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0,
          },
        ],
      }
    }
    return {
      labels: [],
      datasets: []
    }
  }, [overview]);

  return (
    <div className="grid grid-cols-3 gap-4 col-span-2">
      <Card className="col-span-3">
        <CardBody>
          <h6 className="font-semibold mb-2">Total Balance</h6>
          <h3 className="font-semibold">
            {totalLoading ? <Spinner/> : `₦ ${utils.numberWithCommas(total)}`}
          </h3>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Stat>
            <StatLabel className="flex items-center">
              <IconTriangleInvertedFilled
                size={18}
                className="text-green-400 mr-2"/> Inflow
            </StatLabel>
            <StatNumber>
              {isLoading ? <Spinner/> : `₦ ${overview?.["inflows (topup)"].total}`}
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Stat>
            <StatLabel className="flex items-center">
              <IconTriangleFilled
                size={18}
                className="text-red-500 mr-2"/> Outflow
            </StatLabel>
            <StatNumber>
              {isLoading ? <Spinner/> : `₦ ${overview?.["outflows (all)"].total}`}
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Stat>
            <StatLabel className="flex items-center">
              <IconCurrencyNaira
                size={18}
                className="mr-2"/> Revenue
            </StatLabel>
            <StatNumber>
              {isLoading ? <Spinner/> : `₦ ${overview?.all.total}`}
            </StatNumber>
          </Stat>
        </CardBody>
      </Card>

      <Card className="col-span-3">
        <CardBody>
          <div className="grid grid-cols-2 gap-10">
            <div>
              <div className="w-full max-w-xs mx-auto">
                <Pie data={transVolume}/>
              </div>
              <h6 className="font-semibold text-center mt-2">Transaction Volume</h6>
            </div>
            <div>
              <div className="w-full max-w-xs mx-auto">
                <Pie data={revVolume}/>
              </div>
              <h6 className="font-semibold text-center mt-2">Revenue</h6>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default PersonalBalance;
