import React from 'react';
import {
  Card,
  CardBody,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tr
} from "@chakra-ui/react";
import {IconTriangleFilled, IconTriangleInvertedFilled} from "@tabler/icons-react";

interface IProps {
}

function TransactionsBreakdown(props: IProps) {
  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold mb-4">Transactions Breakdown</h6>

        <Tabs position="relative" variant="unstyled" size="sm">
          <TabList>
            <Tab>
              <div className="flex items-center gap-2">
                <IconTriangleInvertedFilled size={16} className="text-green-500"/>
                <p className="small">Inflow</p>
              </div>
            </Tab>
            <Tab>
              <div className="flex items-center gap-2">
                <IconTriangleFilled size={16} className="text-red-500"/>
                <p className="small">Outflow</p>
              </div>
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <Breakdown/>
            </TabPanel>
            <TabPanel>
              <Breakdown/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
    </Card>
  );
}

function Breakdown() {
  return (
    <TableContainer>
      <Table size="sm" variant="simple">
        <Tbody>
          <Tr>
            <Td>Bill Payment</Td>
            <Td>₦ 63,766,937.66</Td>
          </Tr>
          <Tr>
            <Td>Bank Transfer</Td>
            <Td>₦ 63,766,937.66</Td>
          </Tr>
          <Tr>
            <Td>Fago 2 Fago</Td>
            <Td>₦ 63,766,937.66</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default TransactionsBreakdown;
