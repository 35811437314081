import React, {InputHTMLAttributes} from 'react';
import {Input} from "@chakra-ui/react";

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  error?: string | false;
  label?: string;
  size?: "lg" | "md" | "sm"
}

function TextInput({error, label, size = "lg", ...props}: IProps) {
  return (
    <div>
      <label>
        {!!label && <p className="mb-1">{label}</p>}
        <Input
          isInvalid={!!error}
          size={size}
          {...props}
        />
      </label>
      {
        !!error && (
          <p className="text-red-500 small mt-0.5">
            {error}
          </p>
        )
      }
    </div>
  );
}

export default TextInput;
