import {Page} from "../types/common";
import apiInstance from "./api";
import {AxiosResponse} from "axios";
import {
  IFullTransaction,
  ITotalBalance,
  ITransaction,
  ITransactionOverview
} from "../types/transaction.types";
import isEmpty from "is-empty";

class TransactionService {
  getAllTransactions(page = 1, size: number = 10, filters?: object, type: "BUSINESS" | "PERSONAL" = "PERSONAL"): Promise<Page<ITransaction>> {
    return new Promise((resolve, reject) => {
      let filtersApplied: any = {};
      if (filters) {
        const entries = Object.entries(filters);
        for (let i = 0; i < entries.length; i++) {
          const [key, value] = entries[i];
          if (!isEmpty(value)) {
            filtersApplied[key] = value;
          }
        }
      }
      apiInstance.get(type === "PERSONAL" ? "/admin/transactions/all" : "/admin/business/transactions/all", {
        params: {
          page,
          per_page: size, ...filtersApplied
        }
      })
        .then((res: AxiosResponse<any>
          ) => {
            resolve({
              data: res.data.data,
              pagination: res.data.pagination
            });
          }
        )
        .catch(err => reject(err))
    })
  }

  getTransactionDetails(id: string): Promise<IFullTransaction> {
    return new Promise((resolve, reject) => {
      apiInstance.get(`/admin/transactions/view/${id}`)
        .then((res: AxiosResponse<{ data: IFullTransaction }>) => {
          resolve(res.data.data);
        })
        .catch(err => reject(err));
    })
  }

  getTransactionCategories(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/categories")
        .then((res: AxiosResponse<string[]>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    })
  }

  getTotalTransactions(): Promise<number> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/all", {
        params: {total: 1, status: "success"}
      })
        .then((res: AxiosResponse<{ total: number }>) => {
          resolve(res.data.total);
        })
        .catch(err => reject(err));
    })
  }

  getOverview(): Promise<ITransactionOverview> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/overview")
        .then((res: AxiosResponse<ITransactionOverview>) => {
          resolve(res.data)
        })
        .catch(err => reject(err));
    })
  }

  getTotalBalance(): Promise<ITotalBalance> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/total-wallet-balance")
        .then((res: AxiosResponse<ITotalBalance>) => {
          resolve(res.data)
        })
        .catch(err => reject(err));
    })
  }

  getTransactionVolumeByType(type: string): Promise<number> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/all", {
        params: {
          total: 1, category: type
        }
      })
        .then((res: AxiosResponse<{ total: number }>) => {
          resolve(res.data.total);
        })
        .catch(err => reject(err));
    })
  }

  getTransactionCountByType(type: string): Promise<number> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/transactions/all", {
        params: {
          count: 1, category: type
        }
      })
        .then((res: AxiosResponse<{ count: number }>) => {
          resolve(res.data.count);
        })
        .catch(err => reject(err));
    })
  }
}

const transactionService = new TransactionService();
export default transactionService;
