import React, {useEffect} from 'react';
import {useQuery} from "react-query";
import {IAdminDetails} from "../types/auth.types";
import adminService from "../services/admin.service";
import {AdminRoleType} from "../types/admin.types";
import {useNavigate} from "react-router-dom";

interface IProps {
  roles?: AdminRoleType[];
  children: JSX.Element;
}

function PrivateRoute({roles = [], children}: IProps) {
  const {data} = useQuery<IAdminDetails>("userDetails");
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const role = adminService.getRole(data).toLowerCase() as AdminRoleType;
      const haveAccess = roles?.includes(role) || role === "admin";
      if (!haveAccess) {
        navigate("/", {replace: true});
      }
    }
  }, [data, navigate, roles]);

  return children;
}

export default PrivateRoute;
