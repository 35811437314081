import apiInstance from "./api";
import {IGetAllAdmins, IInviteAdmin} from "../types/admin.types";
import {AxiosResponse} from "axios";
import {IAdminDetails} from "../types/auth.types";

class AdminService {
  getAdmins(search?: string | null, page: number = 1, size: number = 10): Promise<IGetAllAdmins> {
    return new Promise((resolve, reject) => {
      apiInstance.get("/admin/adminusers", {
        params: {
          page,
          per_page: size,
          search: search ?? null,
        }
      })
        .then((res: AxiosResponse<IGetAllAdmins>) => {
          resolve(res.data);
        })
        .catch(err => reject(err));
    });
  }

  getRole(data: IAdminDetails): string {
    if (data.admin) {
      return "Admin";
    }
    if (data.finance) {
      return "Finance";
    }
    if (data.support) {
      return "Support";
    }
    return "Unknown";
  }

  invite(data: IInviteAdmin): Promise<boolean> {
    return new Promise((resolve, reject) => {
      apiInstance.post("/admin/invite", data)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }

  enableAdmin(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/enable/${id}`)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    })
  }

  disableAdmin(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/disable/${id}`)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    });
  }

  removeAdmin(id: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      apiInstance.post(`/admin/remove/${id}`)
        .then(res => {
          resolve(true);
        })
        .catch(err => reject(err));
    });
  }

}

const adminService = new AdminService();
export default adminService;
