import React from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tr
} from "@chakra-ui/react";
import utils from "../../../utils/Utils";
import {IUserDetails} from "../../../types/auth.types";
import {IconExternalLink} from "@tabler/icons-react";
import {Link} from "react-router-dom";

interface IProps {
  data: IUserDetails
}

function UserData({data}: IProps) {
  return (
    <Card>
      <CardBody>
        <div className="flex gap-4">
          <div>
            <Avatar/>
          </div>
          <div className="flex-1">
            <div className="flex gap-6">
              <div className="flex-1">
                <h5 className="font-semibold">{data.first_name} {data?.last_name}</h5>
                <p>{utils.getText(data.email)}</p>
                <p>{utils.getText(data.phone_number)}</p>
              </div>
              <Button as={Link} to={`/users/${data.id}`}>
                See user <IconExternalLink className="ml-2"/>
              </Button>
            </div>

            <Alert status="success" className="mt-4">
              {
                !!data?.accountdetail ? (
                  <div>
                    <p>
                      Balance: {" "}
                      <span className="font-semibold">
                      ₦ {utils.numberWithCommas(data.accountdetail?.balance?.toFixed(2))}
                      </span>
                    </p>
                    <p>
                      Daily limit: {" "}
                      <span className="font-semibold">
                      ₦ {utils.numberWithCommas(Number(data.account_daily_limit).toFixed(2))}
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>No account details</p>
                )
              }
            </Alert>
          </div>
        </div>

        <Box p={6} borderWidth="1px" borderRadius="lg" mt={6}>
          <p className="font-semibold mb-4">User Data</p>

          <TableContainer>
            <Table size="sm" variant="simple">
              <Tbody>
                <Tr>
                  <Td>Name</Td>
                  <Td>{utils.getText(`${data.first_name} ${data.last_name}`)}</Td>
                </Tr>
                <Tr>
                  <Td>Email</Td>
                  <Td>{utils.getText(data.email)}</Td>
                </Tr>
                <Tr>
                  <Td>BVN Verified</Td>
                  <Td>
                    <Tag colorScheme={data.bvn_verified ? "green" : "red"}>
                      <TagLabel>{data.bvn_verified ? "True" : "False"}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
                <Tr>
                  <Td>DOB</Td>
                  <Td>
                    {
                      !!data.date_of_birth ?
                        utils.formatDate(new Date(data.date_of_birth)) :
                        "-"
                    }
                  </Td>
                </Tr>
                <Tr>
                  <Td>Phone</Td>
                  <Td>{utils.getText(data.phone_number)}</Td>
                </Tr>
                <Tr>
                  <Td>Acc. No.</Td>
                  <Td>{utils.getText(data.accountdetail?.account_number)}</Td>
                </Tr>
                <Tr>
                  <Td>Bank</Td>
                  <Td>{utils.getText(data.accountdetail?.bank_name)}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </CardBody>
    </Card>
  );
}

export default UserData;
