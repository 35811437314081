import React from 'react';
import {Card, CardBody, Radio, RadioGroup, Stack} from "@chakra-ui/react";

interface IProps {
}

function ThirdParty(props: IProps) {
  const [value, setValue] = React.useState('2')

  return (
    <Card>
      <CardBody>
        <p className="font-semibold mb-2 flex-1">Third Party</p>

        <RadioGroup onChange={setValue} value={value}>
          <Stack direction='row' gap={8}>
            <Radio value='1'>Maple rad</Radio>
            <Radio value='2'>Flutterwave</Radio>
          </Stack>
        </RadioGroup>
      </CardBody>
    </Card>
  );
}

export default ThirdParty;
