import React, {SelectHTMLAttributes} from 'react';
import {ResponsiveValue, Select} from "@chakra-ui/react";
import {ISelectOption} from "../../types/common";

interface IProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "size"> {
  error?: string | false;
  label?: string;
  options: ISelectOption[];
  size?: ResponsiveValue<string | "sm" | "md" | "lg" | "xs"> | undefined
}

function SelectInput({error, label, options, ...props}: IProps) {
  return (
    <div>
      <label>
        {!!label && <p className="mb-1">{label}</p>}
        <Select
          isInvalid={!!error}
          size="lg"
          {...props}
        >
          {
            options.map((option, index) => (
              <option key={`select-${index}`} value={option.value}>{option.label}</option>
            ))
          }
        </Select>
      </label>
    </div>
  );
}

export default SelectInput;
