import React from 'react';
import {Card, CardBody, Stat, StatGroup, StatLabel, StatNumber} from "@chakra-ui/react";
import {useQuery} from "react-query";
import referralService from "../../../services/referral.service";
import {useParams} from "react-router-dom";
import LoadingContainer from "../../../components/LoadingContainer";
import ReferredUsers from "./referredUsers";

interface IProps {
}

function UserReferralStats(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {
    data,
    isLoading
  } = useQuery(["referral", "user", id], () => referralService.getUserStats(id!))

  return (
    <Card>
      <CardBody>
        <div className="flex flex-wrap gap-4 items-center mb-4">
          <h6 className="flex-1 font-semibold">Referral Stats</h6>
          <div>
            <ReferredUsers/>
          </div>
        </div>

        {
          isLoading ? <LoadingContainer/> : (
            <div className="grid gap-6">
              <StatGroup>
                <Stat>
                  <StatNumber>{data?.signups}</StatNumber>
                  <StatLabel>Signups</StatLabel>
                </Stat>
                <Stat>
                  <StatNumber>{data?.kyc}</StatNumber>
                  <StatLabel>KYC</StatLabel>
                </Stat>
              </StatGroup>

              <StatGroup>
                <Stat>
                  <StatNumber>{data?.qualified_txs}</StatNumber>
                  <StatLabel>Qualified Txns</StatLabel>
                </Stat>
                <Stat>
                  <StatNumber>{data?.available_to_withdraw}</StatNumber>
                  <StatLabel>Available to withdraw</StatLabel>
                </Stat>
              </StatGroup>

              <StatGroup>
                <Stat>
                  <StatNumber>{data?.total_earnings}</StatNumber>
                  <StatLabel>Total Earnings</StatLabel>
                </Stat>
                <Stat>
                  <StatNumber>{data?.total_withdrawn}</StatNumber>
                  <StatLabel>Total Withdrawn</StatLabel>
                </Stat>
              </StatGroup>
            </div>
          )
        }

      </CardBody>
    </Card>
  );
}

export default UserReferralStats;
