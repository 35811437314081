import React from 'react';
import * as yup from "yup";
import {Field, FieldProps, Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import {Button} from "@chakra-ui/react";
import {Link} from "react-router-dom";

interface IProps {
}

function ForgotPasswordPage(props: IProps) {
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email address").required("Required")
  })

  const initialValues = {
    email: "",
  }

  const onSubmit = () => {
  }

  return (
    <div>
      <h5 className="font-semibold">Forgot Password</h5>
      <p className="text-slate-400 max-w-md">
        Enter your email to get a password reset link and set new password
      </p>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({isSubmitting, isValid}) => (
          <Form className="grid grid-cols-1 gap-5 mt-6">
            <Field name="email">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Email address"
                  placeholder="Email address"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field>

            <div>
              <Button
                size="lg"
                colorScheme="brand"
                className="w-full"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Reset Password
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <p className="mt-6">
        Remember password? <Link to="/auth" className="text-primary">Login</Link>
      </p>
    </div>
  );
}

export default ForgotPasswordPage;
