import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr
} from "@chakra-ui/react";
import {IconTriangleInvertedFilled} from "@tabler/icons-react";

interface IProps {
}

function TransactionAnalysis(props: IProps) {
  return (
    <Card>
      <CardBody>
        <div className="flex items-center gap-4 mb-6">
          <h6 className="font-semibold mb-2 flex-1">Transactions Analysis</h6>
          <Menu isLazy>
            <MenuButton>
              <Button>Filter <IconTriangleInvertedFilled size={12} className="ml-2"/></Button>
            </MenuButton>
            <MenuList>
              <MenuItem>
                Today
              </MenuItem>
              <MenuItem>
                This week
              </MenuItem>
              <MenuItem>
                This month
              </MenuItem>
              <MenuItem>
                All time
              </MenuItem>
            </MenuList>
          </Menu>
        </div>

        <TableContainer>
          <Table size="sm" variant="simple">
            <Tbody>
              <Tr>
                <Td>Total cost:</Td>
                <Td>N 6,392,434</Td>
              </Tr>
              <Tr>
                <Td>Total charged:</Td>
                <Td>N 6,392,434</Td>
              </Tr>
              <Tr>
                <Td>Total gained:</Td>
                <Td>N 6,392,434</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </CardBody>
    </Card>
  );
}

export default TransactionAnalysis;
