import {extendTheme, ThemeOverride} from "@chakra-ui/react";

const chakraTheme = extendTheme({
  fonts: {
    heading: `Sora, sans-serif`,
    body: `Sora, sans-serif`,
  },
  colors: {
    blue: {
      50: "#F9E9EA",
      100: "#EDBABD",
      200: "#E5989D",
      300: "#D96970",
      400: "#D14C55",
      500: "#C61F2A",
      600: "#B41C26",
      700: "#8D161E",
      800: "#6D1117",
      900: "#530D12",
      main: "#C61F2A",
    },
    brand: {
      50: "#F9E9EA",
      100: "#EDBABD",
      200: "#E5989D",
      300: "#D96970",
      400: "#D14C55",
      500: "#C61F2A",
      600: "#B41C26",
      700: "#8D161E",
      800: "#6D1117",
      900: "#530D12",
      main: "#C61F2A",
    },
  },
} as ThemeOverride);

export default chakraTheme;
