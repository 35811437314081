import React from 'react';
import {Card, CardBody} from "@chakra-ui/react";
import NewAdminModal from "./newAdminModal";

interface IProps {
}

function AdminStats(props: IProps) {
  return (
    <div className="grid grid-cols-4 gap-4">
      <Card>
        <CardBody>
          <p className="mb-2">New Admin</p>
          <NewAdminModal/>
        </CardBody>
      </Card>
    </div>
  );
}

export default AdminStats;
