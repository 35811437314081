import React, {useMemo} from 'react';
import {navItems} from "../../../assets/data/navItems";
import NavItem from "./NavItem";
import {Image, Menu, MenuButton, MenuGroup, MenuItem, MenuList} from "@chakra-ui/react";
import images from "../../../assets/images";
import SidebarProfile from "./SidebarProfile";
import {IconLock, IconLogout, IconSettings, IconUserSquareRounded} from "@tabler/icons-react";
import authService from "../../../services/auth.service";
import {useQuery} from "react-query";
import {IAdminDetails} from "../../../types/auth.types";
import adminService from "../../../services/admin.service";
import {AdminRoleType} from "../../../types/admin.types";
import Notification from "../../Notification";
import ChangePasswordModal from "../../ChangePasswordModal";

interface IProps {
}

function Sidebar(props: IProps) {
  const {data} = useQuery<IAdminDetails>("userDetails");

  const role = useMemo(() => {
    if (data) {
      return adminService.getRole(data).toLowerCase();
    }
    return "unknown"
  }, [data]);

  return (
    <>
      <aside
        className="h-screen w-[250px] fixed left-0 top-0 bottom-0 bg-white border-r border-r-slate-200 flex flex-col pb-5">
        <div className="px-4 pt-4 pb-10 flex items-center gap-4 justify-between">
          <Image src={images.logo}/>
          <Notification/>
        </div>
        <div className="flex-1">
          <div className="grid grid-cols-1">
            {
              navItems
                .filter(n => n.roles === undefined || n.roles.includes(role as AdminRoleType))
                .map((item, idx) => (
                  <NavItem key={`nav-${idx}`} {...item}/>
                ))
            }
          </div>
        </div>
        <div className="border-t border-t-slate-200 pt-5">
          <Menu>
            <MenuButton
              as={SidebarProfile}
              aria-label="Actions"
            />
            <MenuList>
              <MenuGroup title="Profile">
                <MenuItem>
                  <IconUserSquareRounded className="mr-2"/>
                  My Profile
                </MenuItem>
                <MenuItem>
                  <IconSettings className="mr-2"/>
                  Settings
                </MenuItem>
                <ChangePasswordModal/>
                <MenuItem color="red.500" onClick={authService.logout}>
                  <IconLogout className="mr-2"/>
                  Log out
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </div>
      </aside>
      <div/>
    </>
  );
}


export default Sidebar;
