import React from 'react';
import {Card, CardBody, Image} from "@chakra-ui/react";
import images from "../../assets/images";
import {Outlet} from "react-router-dom";

interface IProps {
}

function AuthLayout(props: IProps) {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center px-4 py-6 bg-slate-100">
      <Card className="w-full max-w-md" size="lg">
        <CardBody>
          <Image src={images.logo}/>

          <div className="mt-6">
            <Outlet/>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default AuthLayout;
