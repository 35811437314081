import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tr,
  useDisclosure
} from "@chakra-ui/react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {IUserProfile} from "../../../types/auth.types";
import utils from "../../../utils/Utils";

interface IProps {
}

function UserBankAccounts(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {data} = useQuery<IUserProfile>(["user", id]);
  const {isOpen, onOpen, onClose} = useDisclosure();

  return (
    <>
      <Button onClick={onOpen}>Bank Accounts ({data?.accounts.length})</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>All Bank Accounts ({data?.accounts.length})</ModalHeader>
          <ModalBody>
            <div className="pb-6">
              {
                data?.accounts.map((acct) => (
                  <Box key={acct.id} borderWidth="1px" borderRadius="lg" mt={2}>
                    <Table size="sm" variant="simple">
                      <Tbody>
                        <Tr>
                          <Td>Bank</Td>
                          <Td>{acct.bank_name}</Td>
                        </Tr>
                        <Tr>
                          <Td>Account Name</Td>
                          <Td>{acct.account_name}</Td>
                        </Tr>
                        <Tr>
                          <Td>Account number</Td>
                          <Td>{acct.account_number}</Td>
                        </Tr>
                        <Tr>
                          <Td>Type</Td>
                          <Td>{acct.account_type}</Td>
                        </Tr>
                        <Tr>
                          <Td>Balance</Td>
                          <Td>
                            {acct.currency} {utils.numberWithCommas((acct.balance ?? 0).toFixed(2))}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>Status</Td>
                          <Td>
                            <Tag colorScheme={acct.status === "approved" ? "green" : "red"}>
                              <TagLabel>{acct.status.toUpperCase()}</TagLabel>
                            </Tag>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                ))
              }
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UserBankAccounts;
