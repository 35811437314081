import React, {useState} from 'react';
import {
  Button,
  MenuItem,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {IconSquareRoundedCheck, IconSquareRoundedCheckFilled} from "@tabler/icons-react";
import airtimeCashService from "../../../../services/airtimeCash.service";
import {IAirtimeCashTransaction} from "../../../../types/airtimeCash.types";
import {useQueryClient} from "react-query";

interface IProps {
  transaction: IAirtimeCashTransaction;
  page: number;
}

function ApproveTrans({transaction, page}: IProps) {
  const {isOpen, onClose, onOpen} = useDisclosure();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleApprove = () => {
    if (loading) return;

    setLoading(true);
    airtimeCashService.approveTransaction(transaction.id)
      .then(async res => {
        toast({status: "success", title: "Approved successfully"});
        await queryClient.invalidateQueries(["pendingAirtimeToCash", page])
        setLoading(false)
        onClose();
      })
      .catch(err => {
        toast({status: "error", title: "An error occurred"})
        setLoading(false)
      })
  }

  return (
    <>
      <MenuItem onClick={onOpen} color="green">
        <IconSquareRoundedCheckFilled size={20} className="mr-2"/>
        Approve
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent>
            <div className="p-4 flex flex-col items-center">
              <IconSquareRoundedCheck size={100} strokeWidth={1} className="text-green-500"/>
              <h6 className="font-semibold text-center mt-4">Approve</h6>
              <p>Are you sure you want to approve</p>

              <div className="w-full grid grid-cols-2 gap-4 mt-6">
                <Button onClick={onClose} isDisabled={loading}>
                  Cancel
                </Button>
                <Button colorScheme="green" onClick={handleApprove} isLoading={loading}>
                  Approve
                </Button>
              </div>
            </div>
          </ModalContent>
        </Modal>
      </MenuItem>
    </>
  );
}

export default ApproveTrans;
