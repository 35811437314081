import React from 'react';
import Header from "../../../components/Layout/Header";
import {Card, CardBody, Table, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import TdLink from "../../../components/TdLink";
import {useQuery} from "react-query";
import crmService from "../../../services/crm.service";
import utils from "../../../utils/Utils";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";

interface IProps {
}

function AllGroupsPage(props: IProps) {
  const {isLoading, data, isError} = useQuery("groups", crmService.getAllGroups);

  return (
    <div>
      <Header title="CRM"/>

      <div className="grid gap-4 px-4">
        <Card>
          <CardBody>
            <h6 className="font-semibold">CRM Groups</h6>

            <TableContainer mt={6}>
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Users</Th>
                  <Th>Actions</Th>
                </Thead>
                <Tbody>
                  {
                    isLoading ? (
                      <Tr>
                        <Td colSpan={3}>
                          <p className="text-center">Loading...</p>
                        </Td>
                      </Tr>
                    ) : isError ? (
                      <LoadingErrorContainer className="h-auto"/>
                    ) : (
                      data?.map(group => (
                        <Tr key={group.id} className="with-hover">
                          <TdLink to={`/crm/${group.id}`}>
                            {group.name}
                          </TdLink>
                          <TdLink to={`/crm/${group.id}`}>
                            {utils.numberWithCommas(group.user_count)}
                          </TdLink>
                          <Td>
                            more
                          </Td>
                        </Tr>
                      ))
                    )
                  }
                </Tbody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default AllGroupsPage;
