import React from "react";
import {
  Card,
  CardBody,
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import SelectInput from "../../components/SelectInput";
import {
  IconArrowsDiagonalMinimize2,
  IconDotsVertical,
} from "@tabler/icons-react";
import { useQuery } from "react-query";
import transactionService from "../../services/transaction.service";
import LoadingErrorContainer from "../../components/LoadingErrorContainer";

interface IProps {}

function Transactions(props: IProps) {
  const {
    data: overview,
    isLoading: overviewLoading,
    isLoadingError: overviewLoadingError,
    error: overviewError,
  } = useQuery(["transactions", "overview"], transactionService.getOverview, {
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="px-6 py-4 flex items-center gap-4">
          <div className="flex-1">
            <h6 className="font-semibold">Transactions</h6>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-fit">
              <SelectInput options={[]} placeholder="Duration" size="sm" />
            </div>
            <Menu closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="more"
                icon={<IconDotsVertical />}
                variant="ghost"
                size="sm"
              />
              <MenuList>
                <MenuOptionGroup title="Internal" type="checkbox">
                  <MenuItemOption value="fago2fago">Fago2Fago</MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup title="External" type="checkbox">
                  <MenuItemOption value="inflow">Inflow</MenuItemOption>
                  <MenuItemOption value="outflow">Outflow</MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </div>
        </div>
        <Divider />

        {overviewLoading ? (
          <div className="p-6 text-center">
            <Spinner />
            <p>Loading...</p>
          </div>
        ) : overviewLoadingError && overviewError ? (
          <div>
            <LoadingErrorContainer className="h-auto" />
          </div>
        ) : (
          <div className="p-6 grid gap-4">
            <div className="border border-slate-200 rounded flex items-center px-4 gap-4">
              <div className="w-10 aspect-square rounded-full bg-primary flex items-center justify-center text-white my-4">
                <IconArrowsDiagonalMinimize2 size={20} />
              </div>
              <div className="w-[1px] bg-slate-200 min-h-full self-stretch" />
              <div className="flex-1">
                <div className="flex items-center gap-4">
                  <p>Volume</p>
                  <h6 className="font-semibold ml-auto">
                    {overview?.all.total.replace(")", "").split("(")[1]}
                  </h6>
                </div>
              </div>
            </div>

            <div className="border border-slate-200 rounded flex items-center px-4 gap-4">
              <div className="w-10 aspect-square rounded-full bg-primary flex items-center justify-center text-white my-4">
                <IconArrowsDiagonalMinimize2 size={20} />
              </div>
              <div className="w-[1px] bg-slate-200 min-h-full self-stretch" />
              <div className="flex-1 py-4">
                <div className="flex items-center gap-4">
                  <p>Value</p>
                  <h6 className="font-semibold ml-auto">
                    ₦ {overview?.all.total.split("(")[0]}
                  </h6>
                </div>

                <TableContainer className="mt-2">
                  <Table variant="unstyled" size="sm">
                    <Tbody>
                      <Tr>
                        <Td className="pl-0">Pending</Td>
                        <Td className="text-right pr-0">
                          ₦ {overview?.all.pending.split("(")[0]}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td className="pl-0">Failed</Td>
                        <Td className="text-right pr-0">
                          ₦ {overview?.all.failed.split("(")[0]}
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default Transactions;
