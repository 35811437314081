import React from 'react';
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {AxiosError} from "axios";
import utils from "../../../utils/Utils";
import Header from "../../../components/Layout/Header";
import businessService from "../../../services/business.service";
import {IBusinessDetails} from "../../../types/business.types";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import BusinessData from "./businessData";
import BusinessManagers from "./businessManagers";
import BusinessTransactions from "./transactions";
import BusinessCustomers from "./businessCustomers";
import BusinessInvoices from "./businessInvoices";
import {Card, CardBody} from "@chakra-ui/react";
import BusinessBeneficiaries from "./businessBeneficiaries";

interface IProps {
}

function IndividualBusinessPage(props: IProps) {
  const {id} = useParams<{ id: string }>();
  const {
    isLoading,
    data,
    isLoadingError,
    error
  } = useQuery<IBusinessDetails | undefined, AxiosError<any>>(
    ["business", id],
    () => businessService.getBusiness(id!),
    {refetchOnWindowFocus: false, refetchInterval: false, retry: 1}
  );

  return (
    <div>
      <Header
        title={`Business: ${isLoading ? "..." : utils.getText(`${data?.business_name}`)}`}
      />

      {
        isLoading ? (
          <LoadingContainer/>
        ) : isLoadingError ? (
          <LoadingErrorContainer errorMessage={error?.response?.data?.data?.error}/>
        ) : (
          <div className="grid grid-cols-1 gap-4 px-4">
            <div className="grid grid-cols-3 gap-4">
              <BusinessData/>

              <Card className="col-span-2">
                <CardBody>
                  <div className="grid grid-cols-3 gap-10">
                    <BusinessManagers/>
                    <BusinessCustomers/>
                    <BusinessInvoices/>
                    <BusinessBeneficiaries/>
                  </div>
                </CardBody>
              </Card>
            </div>

            <BusinessTransactions/>

          </div>
        )
      }
    </div>
  );
}

export default IndividualBusinessPage;
