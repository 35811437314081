import React from 'react';
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import {Button} from "@chakra-ui/react";
import TextInput from "../../components/TextInput";
import {Link, useNavigate} from "react-router-dom";
import {ILogin} from "../../types/auth.types";
import authService from "../../services/auth.service";
import utils from "../../utils/Utils";

interface IProps {
}

function LoginPage(props: IProps) {
  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email address").required("Required"),
    password: yup.string().required("Required")
  });

  const initialValues: ILogin = {
    email: "",
    password: "",
  }

  const onSubmit = (values: ILogin, helpers: FormikHelpers<ILogin>) => {
    authService.login(values)
      .then(res => {
        helpers.setSubmitting(false);
        navigate("/");
      })
      .catch(err => utils.handleRequestError(err, helpers))
  }

  return (
    <div>
      <h5 className="font-semibold">Login</h5>
      <p className="text-slate-400 max-w-md">Enter your login details to gain access to the
        dashboard</p>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({isSubmitting, isValid}) => (
          <Form className="grid grid-cols-1 gap-5 mt-6">
            <Field name="email">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Email address"
                  placeholder="Email address"
                  error={meta.touched && meta.error}
                  {...field}
                />
              )}
            </Field
            >
            <Field name="password">
              {({field, meta}: FieldProps) => (
                <TextInput
                  label="Password"
                  placeholder="Password"
                  error={meta.touched && meta.error}
                  type="password"
                  autoComplete="current-password"
                  {...field}
                />
              )}
            </Field>

            <div>
              <Button
                size="lg"
                colorScheme="brand"
                className="w-full"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Login
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      <p className="mt-6">Forgot password? <Link to="/auth/forgot-password"
                                                 className="text-primary">Reset</Link></p>
    </div>
  );
}

export default LoginPage;
