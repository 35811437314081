import React from 'react';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import HomePage from "../pages/Homepage";
import Layout from "../components/Layout";
import UsersPersonalPage from "../pages/Users/Personal";
import UsersBusinessPage from "../pages/Users/Business";
import TransactionsPage from "../pages/Transactions";
import LoginPage from "../pages/Login";
import AuthLayout from "../components/Layout/AuthLayout";
import ForgotPasswordPage from "../pages/ForgotPassword";
import ResetPasswordPage from "../pages/ResetPassword";
import IndividualUserPage from "../pages/Users/IndividualUser";
import Protected from "./Protected";
import IndividualTransactionPage from "../pages/Transactions/IndividualTransaction";
import CC_Personal_BankTransferPage from "../pages/ControlCenter/Personal/BankTransfer";
import CC_Personal_Fago2FagoPage from "../pages/ControlCenter/Personal/Fago2Fago";
import CC_PersonalPage from "../pages/ControlCenter/Personal";
import CC_Personal_AirtimeToCashPage from "../pages/ControlCenter/Personal/AirtimeToCash";
import ReferralPage from "../pages/ControlCenter/Referral";
import AdminPage from "../pages/Admin";
import RegisterPage from "../pages/Register";
import PrivateRoute from "./PrivateRoute";
import IndividualBusinessPage from "../pages/Users/IndividualBusiness";
import AllGroupsPage from "../pages/CRM/AllGroupsPage";
import CRMGroupPage from "../pages/CRM/CRMGroupPage";
import CrmMessaagesPage from '../pages/CRM/MessagesPage';
import CrmTemplatesPage from '../pages/CRM/TemplatesPage';

interface IProps {
}

function RootNavigation(props: IProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Protected><Layout/></Protected>}>
          <Route path="" element={<HomePage/>}/>

          <Route
            path="users"
            element={<PrivateRoute roles={["admin", "support"]}><Outlet/></PrivateRoute>}
          >
            <Route path="personal" element={<UsersPersonalPage/>}/>
            <Route path="business" element={<UsersBusinessPage/>}/>
            <Route path="business/:id" element={<IndividualBusinessPage/>}/>
            <Route path=":id" element={<IndividualUserPage/>}/>
          </Route>

          <Route
            path="transactions"
            element={<PrivateRoute roles={["admin", "finance"]}><Outlet/></PrivateRoute>}
          >
            <Route path="" element={<TransactionsPage/>}/>
            <Route path=":id" element={<IndividualTransactionPage/>}/>
          </Route>

          <Route
            path="crm"
            element={<PrivateRoute roles={["admin", "support"]}><Outlet/></PrivateRoute>}
          >
            <Route path="" element={<AllGroupsPage/>}/>
            <Route path=":groupId" element={<CRMGroupPage/>}/>
            <Route path="messages" element={<CrmMessaagesPage/>}/>
            <Route path="templates" element={<CrmTemplatesPage/>}/>
          </Route>

          <Route
            path="control"
            element={<PrivateRoute roles={["admin"]}><Outlet/></PrivateRoute>}
          >
            <Route path="personal">
              <Route path="" element={<CC_PersonalPage/>}/>
              <Route path="bank-transfer" element={<CC_Personal_BankTransferPage/>}/>
              <Route path="fago-fago" element={<CC_Personal_Fago2FagoPage/>}/>
              <Route path="airtime-cash" element={<CC_Personal_AirtimeToCashPage/>}/>
            </Route>
            <Route path="referral" element={<ReferralPage/>}/>
          </Route>

          <Route
            path="/admin"
            element={<PrivateRoute roles={["admin"]}><AdminPage/></PrivateRoute>}
          />
        </Route>

        <Route element={<AuthLayout/>}>
          <Route path="register" element={<RegisterPage/>}/>
          <Route path="auth">
            <Route path="" element={<LoginPage/>}/>
            <Route path="forgot-password" element={<ForgotPasswordPage/>}/>
            <Route path="reset-password" element={<ResetPasswordPage/>}/>
          </Route>
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default RootNavigation;
