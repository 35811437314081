import React from "react";
import {
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { IconDotsVertical, IconInfoSquareRounded } from "@tabler/icons-react";
import Pagination from "../Pagination";
import FilterTransactionsTable from "./filterTransactionsTable";
import { ITransaction } from "../../types/transaction.types";
import utils from "../../utils/Utils";
import { IPagination } from "../../types/common";
import { Link } from "react-router-dom";
import ExportTransactions from "./exportTransactions";
import TdLink from "../TdLink";

interface IProps {
  data: ITransaction[];
  pagination?: IPagination;
  setPage?: (page: number) => void;
  applyFilter?: (filter: Map<string, any>) => void;
  appliedFilter?: any;
  size?: number;
  setSize?: (e: number) => void;
  fetchAllForExport?: () => Promise<ITransaction[]>;
}

function TransactionsTable({
  data,
  pagination,
  setPage,
  applyFilter,
  appliedFilter,
  size,
  setSize,
  fetchAllForExport,
}: IProps) {
  return (
    <Card>
      <CardBody>
        <div className="flex items-center flex-wrap mb-4">
          <h5 className="font-semibold flex-1">All Transactions</h5>
          <div className="flex gap-2">
            <FilterTransactionsTable
              applyFilter={applyFilter}
              appliedFilter={appliedFilter}
            />
            {!!fetchAllForExport && (
              <ExportTransactions fetchAllForExport={fetchAllForExport} />
            )}
          </div>
        </div>

        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>S/N</Th>
                <Th>Reference</Th>
                <Th>Amount</Th>
                <Th>User</Th>
                <Th>To-From</Th>
                <Th>Type</Th>
                <Th>Status</Th>
                <Th>Category</Th>
                <Th>Date created</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((transaction, index) => (
                <Tr key={transaction.id} className="with-hover">
                  <TdLink to={`/transactions/${transaction.id}`}>
                    {((pagination?.current_page ?? 1) - 1) * (size ?? 0) +
                      (index + 1)}
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    {transaction.reference}
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    ₦{" "}
                    {utils.numberWithCommas(
                      (transaction.transaction_type === "debit"
                        ? transaction.outflow
                        : transaction.inflow
                      ).toFixed(2)
                    )}
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    {transaction.user.first_name} {transaction.user.last_name}
                  </TdLink>
                  <TdLink
                    to={`/transactions/${transaction.id}`}
                    className="max-w-[14rem] overflow-hidden"
                    title={transaction.to_from}
                  >
                    <span className="truncate block">
                      {transaction.to_from}
                    </span>
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    <Tag
                      colorScheme={
                        transaction.transaction_type === "debit"
                          ? "red"
                          : "green"
                      }
                    >
                      <TagLabel>
                        {transaction.transaction_type.toUpperCase()}
                      </TagLabel>
                    </Tag>
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    <Tag
                      colorScheme={
                        transaction.transaction_status === "success"
                          ? "green"
                          : transaction.transaction_status === "pending"
                          ? "gray"
                          : "red"
                      }
                    >
                      <TagLabel>
                        {transaction.transaction_status.toUpperCase()}
                      </TagLabel>
                    </Tag>
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    {transaction.category}
                  </TdLink>
                  <TdLink to={`/transactions/${transaction.id}`}>
                    {!!transaction.created_at
                      ? utils.formatDate(new Date(transaction.created_at), true)
                      : "-"}
                  </TdLink>
                  <Td>
                    <Menu isLazy>
                      <MenuButton
                        as={IconButton}
                        icon={<IconDotsVertical size={20} />}
                        size="sm"
                        aria-label="Actions"
                        isRound
                      />
                      <MenuList>
                        <MenuItem
                          as={Link}
                          to={`/transactions/${transaction.id}`}
                        >
                          <IconInfoSquareRounded size={20} className="mr-2" />
                          See more info
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {pagination && (
          <Pagination
            pageCount={Math.ceil(pagination.total / pagination.per_page)}
            onPageChange={(e) => (!!setPage ? setPage(e.selected + 1) : null)}
            forcePage={(pagination.current_page ?? 1) - 1}
            pagination={pagination}
            size={size}
            onSizeChange={setSize}
          />
        )}
      </CardBody>
    </Card>
  );
}

export default TransactionsTable;
