import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as yup from "yup";
import {IInviteAdmin} from "../../types/admin.types";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import adminService from "../../services/admin.service";
import utils from "../../utils/Utils";
import {useQueryClient} from "react-query";

interface IProps {
}

function NewAdminModal(props: IProps) {
  const {onOpen, onClose, isOpen} = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email address").required("Required"),
    role: yup.string().required("Required"),
  });

  const initialValues: IInviteAdmin = {
    email: "",
    role: "support",
  }

  const onSubmit = (values: IInviteAdmin, helpers: FormikHelpers<IInviteAdmin>) => {
    adminService.invite(values)
      .then(res => {
        helpers.setSubmitting(false);
        toast({status: "success", title: "Admin user invited successfully"});
        queryClient.invalidateQueries(["admins", 1]);
        onClose();
      })
      .catch(err => {
        utils.handleRequestError(err, helpers)
      })
  }

  return (
    <>
      <Button colorScheme="brand" onClick={onOpen}>
        Add new
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalCloseButton/>
          <ModalHeader>Add new admin</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({isSubmitting, isValid}) => (
                <Form className="grid gap-4 pb-8">
                  <Field name="email">
                    {({field, meta}: FieldProps) => (
                      <TextInput
                        label="Email address"
                        placeholder="Email Address"
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <Field name="role">
                    {({field, meta}: FieldProps) => (
                      <SelectInput
                        label="Role"
                        placeholder="Role"
                        options={[
                          {
                            label: "Admin",
                            value: "admin",
                          },
                          {
                            label: "Support",
                            value: "support",
                          },
                          {
                            label: "Finance",
                            value: "finance",
                          },
                        ]}
                        error={meta.touched && meta.error}
                        {...field}
                      />
                    )}
                  </Field>

                  <div className="mt-4">
                    <Button
                      colorScheme="brand"
                      size="lg"
                      className="w-full"
                      type="submit"
                      isLoading={isSubmitting}
                      isDisabled={!isValid}
                    >
                      Invite
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NewAdminModal;
