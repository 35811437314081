import React from 'react';
import {Card, CardBody, Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {IReferralCategory} from "../../../types/referral.types";
import {useQuery} from "react-query";
import referralService from "../../../services/referral.service";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingErrorContainer from "../../../components/LoadingErrorContainer";
import ReferralCategoryModal from "./modal";

interface IProps {
}

function ReferralCategories(props: IProps) {
  const {
    data,
    isLoading,
    isLoadingError
  } = useQuery("referralCategories", referralService.getReferralCategories);

  return (
    <Card>
      <CardBody>
        <h6 className="font-semibold mb-2">Categories</h6>

        {
          isLoading ?
            <LoadingContainer/> :
            isLoadingError ?
              <LoadingErrorContainer/> :
              (
                <Table>
                  <Thead>
                    <Th>Name</Th>
                    <Th>Description</Th>
                    <Th>Amount per point</Th>
                    <Th>Base withdraw</Th>
                    <Th>Referral bonus</Th>
                    <Th>Referral person bonus</Th>
                    <Th>Action</Th>
                  </Thead>
                  <Tbody>
                    {
                      data?.map((category) => <CategoryRow data={category} key={category.id}/>)
                    }
                  </Tbody>
                </Table>
              )
        }
      </CardBody>
    </Card>
  );
}

function CategoryRow({data}: { data: IReferralCategory }) {
  return (
    <Tr>
      <Td>{data.name}</Td>
      <Td>{data.description}</Td>
      <Td>{data.amount_per_point}</Td>
      <Td>{data.base_withdraw_amount}</Td>
      <Td>{data.referrer_bonus}</Td>
      <Td>{data.referred_person_bonus}</Td>
      <Td><ReferralCategoryModal data={data}/></Td>
    </Tr>
  )
}

export default ReferralCategories;
