import React from 'react';
import RootNavigation from "./navigation/RootNavigation";
import {ChakraProvider} from "@chakra-ui/react";
import chakraTheme from "./assets/data/chakraTheme";
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {QueryClient, QueryClientProvider} from "react-query";
import AppLoading from "./components/AppLoading";
import authService from "./services/auth.service";
import {Toaster} from "react-hot-toast";

const queryClient = new QueryClient();

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        onBeforeLift={async () => await authService.reAuthenticate()}
        loading={<AppLoading/>}
        persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={chakraTheme}>
            <RootNavigation/>
            <Toaster/>
          </ChakraProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
