import apiInstance from "./api";
import {AxiosResponse} from "axios";
import {
  CRMGroup,
  CRMGroupUsersResponse,
  CRMMessage,
  CRMSendMessageRequest,
  CRMTemplate,
} from "../types/crm.types";
import {IPagination} from "../types/common";

class CrmService {
  getAllGroups(): Promise<CRMGroup[]> {
    return new Promise((resolve, reject) => {
      apiInstance
        .get("/admin/groups")
        .then((res: AxiosResponse<{ groups: CRMGroup[] }>) => {
          resolve(res.data.groups);
        })
        .catch((err) => reject(err));
    });
  }

  getGroupUsers(
    groupId: number,
    page: number = 1,
    size: number = 10
  ): Promise<CRMGroupUsersResponse> {
    return new Promise((resolve, reject) => {
      apiInstance
        .get("/admin/groups/users", {
          params: {
            group_id: groupId,
            page,
            per_page: size,
          },
        })
        .then((res: AxiosResponse<CRMGroupUsersResponse>) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  addUserToGroup(userId: string, groupId: number) {
    return new Promise((resolve, reject) => {
      apiInstance
        .post("/admin/groups/user/add", {
          group_id: groupId,
          user_id: userId,
        })
        .then((res) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  removeUserFromGroup(userId: string, groupId: number) {
    return new Promise((resolve, reject) => {
      apiInstance
        .post("/admin/groups/user/remove", {
          group_id: groupId,
          user_id: userId,
        })
        .then((res) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  getMessages(
    page: number = 1,
    size: number = 10
  ): Promise<{ data: { data: CRMMessage[] }; pagination: IPagination }> {
    return new Promise((resolve, reject) => {
      apiInstance
        .get("/admin/messages", {params: {page, per_page: size}})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }

  sendMessage(data: CRMSendMessageRequest) {
    return new Promise((resolve, reject) => {
      apiInstance
        .post("/admin/messages/send", data)
        .then((res) => {
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  getTemplates(page: number = 1, size: number = 10): Promise<CRMTemplate[]> {
    return new Promise((resolve, reject) => {
      apiInstance
        .get("/admin/messages/templates", {params: {page, per_page: size}})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  }
}

const crmService = new CrmService();
export default crmService;
