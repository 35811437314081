import React from 'react';
import Sidebar from "./Sidebar";
import {Outlet} from "react-router-dom";
import {useQuery} from "react-query";
import authService from "../../services/auth.service";
import AppLoading from "../AppLoading";
import LoadingErrorContainer from "../LoadingErrorContainer";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS, Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Filler,
);

interface IProps {
}

function Layout(props: IProps) {
  const {
    isLoading,
    isError,
    refetch,
    isRefetching
  } = useQuery("userDetails", authService.getUserDetails);

  if (isLoading) {
    return <AppLoading/>
  }

  if (isError) {
    return (
      <LoadingErrorContainer isRefetching={isRefetching} refetch={refetch}/>
    )
  }

  return (
    <div className="w-full min-h-screen grid grid-cols-[minmax(0,250px)_minmax(0,1fr)]">
      <Sidebar/>
      <main className="bg-slate-100 pb-4">
        <Outlet/>
      </main>
    </div>
  );
}

export default Layout;
