import React from 'react';
import {Spinner} from "@chakra-ui/react";

interface IProps {
}

function LoadingContainer(props: IProps) {
  return (
    <div className="h-40 flex items-center justify-center flex-col gap-4">
      <Spinner/>
      <p className="small">Loading...</p>
    </div>
  );
}

export default LoadingContainer;
