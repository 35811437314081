import React from 'react';
import {IconBug} from "@tabler/icons-react";
import {Button} from "@chakra-ui/react";

interface IProps {
  isRefetching?: boolean,
  refetch?: () => void,
  errorMessage?: string,
  className?: string,
}

function LoadingErrorContainer(
  {
    isRefetching,
    refetch,
    errorMessage,
    className = "h-screen"
  }: IProps) {
  return (
    <div className={`flex flex-col gap-4 items-center justify-center p-5 ${className}`}>
      <IconBug size={48} strokeWidth={1.5} className="text-red-500"/>
      <h5 className="text-red-500 font-bold">Oops</h5>
      <p>{errorMessage ?? "An error occurred"}</p>
      <div>
        {
          !!refetch && (
            <Button isLoading={isRefetching} onClick={() => refetch()}>Retry</Button>
          )
        }
      </div>
    </div>
  );
}

export default LoadingErrorContainer;
