import React, {ButtonHTMLAttributes, useMemo} from 'react';
import {Avatar} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {IAdminDetails} from "../../../types/auth.types";
import adminService from "../../../services/admin.service";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
}

const SidebarProfile = React.forwardRef<HTMLButtonElement>(({className, ...props}: IProps, ref) => {
  const {data} = useQuery<IAdminDetails>("userDetails");

  const role = useMemo(() => {
    if (data) {
      return adminService.getRole(data);
    }
    return "..."
  }, [data])

  return (
    <button ref={ref}
            className={`flex gap-2 items-center p-4 text-left w-full ${className}`} {...props}>
      <Avatar name={`${data?.first_name} ${data?.last_name}`} size="sm"/>
      <div>
        <p className="leading-none">{data?.first_name} {data?.last_name}</p>
        <p className="caption">{role}</p>
      </div>
    </button>
  );
})

export default SidebarProfile;
