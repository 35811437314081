import React, {useEffect, useState} from 'react';
import {
  Card,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {IconDotsVertical} from "@tabler/icons-react";
import Pagination from "../../../components/Pagination";
import FilterBusinessTable from "./filterBusinessTable";
import {useQuery} from "react-query";
import businessService from "../../../services/business.service";
import LoadingContainer from "../../../components/LoadingContainer";
import utils from "../../../utils/Utils";
import TdLink from "../../../components/TdLink";
import isEmpty from "is-empty";

interface IProps {
}

function BusinessTable(props: IProps) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [filterApplied, setFilterApplied] = useState<object>({});

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterApplied)]);

  const {data, isLoading} = useQuery(
    ["businesses", page, size, isEmpty(filterApplied) ? "NO_FILTER" : JSON.stringify(filterApplied)],
    () => businessService.getBusinesses(page, size, filterApplied),
    {keepPreviousData: true, refetchOnWindowFocus: false}
  );

  return (
    <Card>
      <CardBody>
        <div className="flex items-center flex-wrap mb-4">
          <h5 className="font-semibold flex-1">All Businesses</h5>
          <FilterBusinessTable setFilter={setFilterApplied} filter={filterApplied}/>
        </div>

        {
          isLoading ? (
            <LoadingContainer/>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>S/N</Th>
                      <Th>Business Name</Th>
                      <Th>Email</Th>
                      <Th>Phone Number</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {
                      data?.data.map((biz, index) => (
                        <Tr key={`tr-${biz.id}`} className="with-hover">
                          <TdLink to={`/users/business/${biz.id}`}>
                            {((page - 1) * size) + (index + 1)}
                          </TdLink>
                          <TdLink to={`/users/business/${biz.id}`}>
                            {utils.getText(biz.business_name)}
                          </TdLink>
                          <TdLink to={`/users/business/${biz.id}`}>
                            {utils.getText(biz.email)}
                          </TdLink>
                          <TdLink to={`/users/business/${biz.id}`}>
                            {utils.getText(biz.phone_number)}
                          </TdLink>
                          <Td>
                            <Menu isLazy>
                              <MenuButton
                                as={IconButton}
                                icon={<IconDotsVertical size={20}/>}
                                size="sm"
                                aria-label="Actions"
                                isRound
                              />
                              <MenuList>
                                <MenuItem>
                                  Deactivate
                                </MenuItem>
                                <MenuItem>
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))
                    }

                  </Tbody>
                </Table>
              </TableContainer>

              {
                data?.pagination &&
                  <Pagination
                      pageCount={Math.ceil(data.pagination.total / data.pagination.per_page)}
                      onPageChange={e => setPage(e.selected + 1)}
                      forcePage={(data.pagination.current_page ?? 1) - 1}
                      pagination={data.pagination}
                      size={size}
                      onSizeChange={setSize}
                  />
              }
            </>
          )
        }
      </CardBody>
    </Card>
  );
}

export default BusinessTable;
