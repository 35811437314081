import React from 'react';
import {Card, CardBody, Divider, IconButton} from "@chakra-ui/react";
import {Line} from "react-chartjs-2";
import {ChartData, ChartOptions, Point} from "chart.js";
import {IconDotsVertical} from "@tabler/icons-react";

interface IProps {
}

function TransactionRateChart(props: IProps) {
  const data: ChartData<"line", (number | Point | null)[], unknown> = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Successful",
        data: [250, 350, 300, 340, 450, 400, 380, 360, 300, 450, 320, 280],
        borderColor: "#B21C26",
        pointBackgroundColor: "#FFFFFF",
        backgroundColor: (context) => {
          if (context.chart.chartArea) {
            const {ctx, chartArea: {top, bottom}} = context.chart;
            const gradBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradBg.addColorStop(0, "rgba(198, 28, 38, 0.1)");
            gradBg.addColorStop(1, "rgba(198, 28, 38, 0)");

            return gradBg;
          }
          return "rgba(198, 28, 38, 0.2)";
        },
        fill: true,
      },
      {
        label: "Unsuccessful",
        data: [180, 200, 180, 250, 220, 300, 220, 210, 220, 200, 210, 180],
        borderColor: "#2D2D2D",
        pointBackgroundColor: "#FFFFFF",
        backgroundColor: (context) => {
          if (context.chart.chartArea) {
            const {ctx, chartArea: {top, bottom}} = context.chart;
            const gradBg = ctx.createLinearGradient(0, top, 0, bottom);
            gradBg.addColorStop(0, "rgba(45, 45, 45, 0.1)");
            gradBg.addColorStop(1, "rgba(45, 45, 45, 0)");

            return gradBg;
          }
          return "rgba(45, 45, 45, 0.2)";
        },
        fill: true,
      },
    ]
  }

  const options: ChartOptions<"line"> = {
    plugins: {
      legend: {
        align: "end",
        labels: {
          useBorderRadius: true,
          borderRadius: 6,
          boxHeight: 12,
          boxWidth: 12,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        }
      }
    },
    elements: {
      point: {
        borderWidth: 3,
        hoverBorderWidth: 6,
        radius: 6,
        hoverRadius: 8,
        backgroundColor: "#FFFFFF",
        hoverBackgroundColor: "#FFFFFF",
      }
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    maintainAspectRatio: false,
  }

  return (
    <Card className="overflow-hidden">
      <CardBody className="p-0">
        <div className="px-6 py-4 flex items-center gap-4">
          <div className="flex-1">
            <h6 className="font-semibold">Transaction Rate</h6>
          </div>
          <div className="flex items-center gap-2">
            <IconButton
              aria-label="more"
              icon={<IconDotsVertical/>}
              variant="ghost"
              size="sm"
            />
          </div>
        </div>

        <Divider/>

        <div className="p-10 h-[350px]">
          <Line data={data} options={options}/>
        </div>
      </CardBody>
    </Card>
  );
}

export default TransactionRateChart;
